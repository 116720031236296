import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMember } from '../../actions/member';
import './membership.css';
import moment from 'moment';

const PaymentHistory = ({ match }) => {
    const { currentUser, loading } = useSelector(({ member }) => member);

    const dispatch = useDispatch();
    const id = match.params.id;

    useEffect(() => {
        dispatch(getMember(id));
    }, [currentUser.id]);


  
  return (
      loading ? <p>Loading</p> :
    <div className="subscription-container">   
        <h1 className="subscription-header">Payment History</h1>
        { currentUser?.recent_transactions.map((transaction, index) => (
          <div key={index} className="active-subscriptions">
            <div className="transaction-container">
              <p className="subscription-title">Your transaction was completed on {moment(transaction.created_at).format('MMMM, DD, YYYY')} for ${transaction.total}</p>
            </div>
        </div>
        ))
        }
        

    </div>
  );
}

export default PaymentHistory;
