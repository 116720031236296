import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logout } from '../actions/auth';
import './menu.css';

const Menu = ({ open, setOpen}) => {

    const { token } = useSelector(({ auth }) => auth);
    const { profile, mentor } = useSelector(({ member }) => member);
    const dispatch = useDispatch();

    const logoutUser = () =>  {
        dispatch(logout());
        setOpen(false);
    }

    let assignedMentor;

    useEffect(() => {
         assignedMentor = mentor
    }, [mentor])

    const getLinkForMentor = (mentor) => {
        if(mentor === 'Alicia Unfinished') {
            return `/mentor/alicia`
        } 

        if(mentor === 'Null') return ''

        if(mentor.includes('Completed')) {
           mentor =  mentor.split(' ')[0]
            return `/mentor/${mentor}`
        } 
            
        return `/mentor/${mentor}`
    }
   
  

    const authLinks = (
        <Fragment>
            {   
                !mentor ? null :
                <li className="navigation-items"><Link onClick={() => setOpen(false)} to={getLinkForMentor(mentor) }> {mentor === 'Null' ? null : 'HEM Course'} </Link></li>
            }
                
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/workspace'>Videos & Courses</Link></li>
            <li className="navigation-items"><a target="_blank" onClick={() => setOpen(false)} href='http://habitextensionmethod.com/'>Upcoming Classes</a></li>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/one-on-one'>1 On 1 HEM Certification</Link></li>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/locator'>Salon Locator</Link></li>
            <li className="navigation-items"><Link onClick={() => logoutUser()} to='/'>Logout</Link></li>
        </Fragment>

    );

    const guestLinks = (
        <Fragment>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/workspace'>Videos & Courses</Link></li>
            <li className="navigation-items"><a target="_blank" onClick={() => setOpen(false)} href='http://habitextensionmethod.com/'>Upcoming Classes</a></li>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/one-on-one'>1 On 1 HEM Certification</Link></li>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/locator'>Salon Locator</Link></li>
            <li className="navigation-items"><Link onClick={() => setOpen(false)} to='/login'>Login</Link></li>
            <h1 className="guest-cta">Learn something new today</h1>
            <h3 className="guest-cta-small">Get all 300+ videos in 10 categories</h3>
            <Link className="cta-btn" to="login">Get Started</Link>
            <h3 className="guest-cta-small">As low as $25 / mo</h3>
        </Fragment>
        
    );

    return (
        <div className={open ? "navigation-list open" : "navigation-list closed"}>
            <ul className='navigation-item-container'>
            { token ? authLinks : guestLinks }
            </ul>
        </div>
    )
};

export default Menu;