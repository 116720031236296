import { GET_MEMBERSHIP_SUCCESS, GET_MEMBERSHIP_REQUEST, GET_MEMBERSHIP_FAILED } from "../actions/types";

const initialState = {
    membership: {},
    loading: false
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_MEMBERSHIP_SUCCESS:
            return {
                membership: {...payload},
                loading: false
            }
        case GET_MEMBERSHIP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MEMBERSHIP_FAILED:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}