import React from 'react';
import { Link } from 'react-router-dom';
import Play from '../../icons/Play';
import Completed from '../../icons/Completed';

const UnavailableLessons = ({ lesson }) => {
    return (
        <Link key={lesson.id} className="lesson-link">
            <div className="course-lesson-container">
                <h3 className="section-title">{lesson.section_heading} </h3>
                <div className="upcoming-lesson-section">
                    <div className="lesson-metadata">
                        {!lesson.completed_lesson ? <Play link={`/lesson/${lesson.id}`}/>  : <Completed link={`/lesson/${lesson.id}`}/>  }
                        <p className="lesson-title">{lesson.title}</p>
                    </div>
                    <p className="upcoming-lesson">{lesson.drip_date_as_date ? `Available on ${lesson.drip_date_as_date}` : 'Your class will begin soon' } </p>
                </div>
            </div>
        </Link>
    )
}

export default UnavailableLessons;