import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getLessons, setCourseId } from '../actions/courseLessons';
import Play from './icons/Play'
import Completed from './icons/Completed'
import './singleCourse.css'
import LessonSkeleton from './hem/mentors/LessonSkeleton';
import UpgradeLessons from './hem/lessonStatuses/UpgradeLessons';
import UnavailableLessons from './hem/lessonStatuses/UnavailableLessons';
import AvailableLessons from './hem/lessonStatuses/AvailableLessons';

const SingleCourse = ({ match }) => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { lessons, loading } = useSelector(({ courseLessons }) => courseLessons); 
    
    
    const dispatch = useDispatch();
    const id = match.params.id;   
    console.log(id);
    
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getLessons(id));
        dispatch(setCourseId(id));
    }, [lessons.id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
      }

      const renderLessonStatus = (s) => {
        if(s.upgrade_section) return <UpgradeLessons lesson={s}/>
        if( new Date(now) < new Date(s.drip_date_as_date)) return <UnavailableLessons lesson={s}/>
        return <AvailableLessons lesson={s}/>
    }

    let now = new Date().toLocaleDateString('en-us',  {year:"numeric", month:"long", day:"numeric"});

    const courseLessons = lessons.map(lesson => (
     new Date(now) > new Date(lesson.drip_date_as_date) || id != '252414' ?
      <Link className="lesson-link" to={lesson.assignments ? `/course/assignment/${lesson.id}` : `/course/lesson/${lesson.id}`}>
          <div className="course-lesson-container">
              <h3 className="section-title"> {lesson.section_heading} </h3>
              <div className="lesson-section">
                  <div className="lesson-metadata">
                      {!lesson.completed_lesson ? <Play link={`/lesson/${lesson.id}`}/>  : <Completed link={`/lesson/${lesson.id}`}/>  }
                      <p className="lesson-title">{lesson.title}</p>
                  </div>
              </div>
          </div>
      </Link>
      :
        renderLessonStatus(lesson)
    ))

  return (
    <div className="general-lesson-container">
     { loading ? <LessonSkeleton/> 
     : 
     courseLessons
    }

      
    </div>
  );
}

export default SingleCourse;
