import { GET_POSTS, 
    GET_POSTS_REQUEST,
    GET_POSTS_FAILED,
    GET_POSTS_PREVIEW_SUCCESS,
    GET_POSTS_PREVIEW_FAILED,
    GET_POSTS_PREVIEW_REQUEST, 
} from "../actions/types";

const initialState = {
    posts: [],
    loading: false
}

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    
    switch(type) {
        case GET_POSTS:
        case GET_POSTS_PREVIEW_SUCCESS:
            return {
                ...state,
                posts: [...payload],
                loading: false
            }
        case GET_POSTS_FAILED: 
        case GET_POSTS_PREVIEW_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_POSTS_REQUEST:
        case GET_POSTS_PREVIEW_REQUEST:
            return {
                ...state,
                loading: true
            }
        default: 
            return state;
    }
};