import { GET_MEMBER, GET_MEMBER_FAILED, GET_MEMBER_REQUEST, GET_MEMBER_PROFILE_REQUEST, GET_MEMBER_PROFILE_FAILED, GET_MEMBER_PROFILE_SUCCESS } from '../actions/types';

const initalState = {
    currentUser: {},
    profile: {},
    loading: false,
    activeSubscription: JSON.parse(localStorage.getItem('active_subscriptions')),
    mentor: JSON.parse(localStorage.getItem('mentor'))
};

export default function (state = initalState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_MEMBER:
            localStorage.setItem('active_subscriptions', JSON.stringify(payload.active_memberships))
            return {
                ...state,
                currentUser: {...payload},
                loading: false
            }
        case GET_MEMBER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MEMBER_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_MEMBER_PROFILE_SUCCESS:
            localStorage.setItem('mentor', JSON.stringify(payload.mentor))
            return {
                ...state,
                profile: {...payload},
                mentor: payload.mentor,
                loading: false
            }
        case GET_MEMBER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MEMBER_PROFILE_FAILED:
            return {
                ...state,
                loading: false
            }
        default: 
            return state;
    }
};