import http from '../utils/http';
import {  
    GET_COURSE_LESSONS,
    GET_COURSE_LESSONS_FAILED,
    GET_COURSE_LESSONS_REQUEST,
    GET_COURSE_LESSON,
    GET_COURSE_LESSON_FAILED,
    GET_COURSE_LESSON_REQUEST,
    MARK_LESSON_COMPLETED_FAILED,
    MARK_LESSON_COMPLETED_SUCCESS,
    SET_COURSE_ID_SUCCESS,
    SET_COURSE_ID_FAILED
 } from "./types";
 
 export const setCourseId = (id) => async dispatch => {
    try {
        dispatch({
            type: SET_COURSE_ID_SUCCESS,
            payload: id
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: SET_COURSE_ID_FAILED
        })
    }
 }


 export const getLessons = (id) => async dispatch => {
     try {
         dispatch({type: GET_COURSE_LESSONS_REQUEST});
         const res = await http.get(`wp/v2/custom/lessons/course/${id}`)
         dispatch({
             type: GET_COURSE_LESSONS,
             payload: res.data
           })
     } catch (err) {
         console.log(err);
         dispatch({
             type: GET_COURSE_LESSONS_FAILED
         })
     }
 };

 export const getLessonById = (id) => async dispatch => {
    try {
        dispatch({type: GET_COURSE_LESSON_REQUEST});
        const res = await http.get(`wp/v2/custom/course/lesson/${id}`)
        dispatch({
            type: GET_COURSE_LESSON,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_COURSE_LESSON_FAILED
        })
    }
};

export const markLessonCompleted = (lessonId) => async dispatch => {
    try {
        const res = await http.post(`wp/v2/custom/lesson/completed/${lessonId}?status=true`)

        dispatch({
            type: MARK_LESSON_COMPLETED_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: MARK_LESSON_COMPLETED_FAILED
        })
    }
}




 