import http from '../utils/http';
import { GET_CONTENT_CATEGORIES_SUCCESS, GET_CONTENT_CATEGORIES_REQUEST, GET_CONTENT_CATEGORIES_FAILED } from './types';

export const getCategories = () => async dispatch => {
    
    try {
        dispatch({ type: GET_CONTENT_CATEGORIES_REQUEST});

        const res = await http.get('wp/v2/custom/posts-by-rules');
        dispatch({
            type: GET_CONTENT_CATEGORIES_SUCCESS,
            payload: res.data
        })

    } catch (err) {
        console.log(err)
        dispatch({
            type: GET_CONTENT_CATEGORIES_FAILED
        })
    }
}