import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonById, markLessonCompleted } from '../../actions/courseLessons';
import Vimeo from '@u-wave/react-vimeo';
import SinglePostSkeleton from '../skeleton/SinglePostSkeleton';
import UpnextVideosSkeleton from '../skeleton/UpnextVideosSkeleton';
import CourseNextVideos from './CourseNextVideos';
import '../SinglePost.css';
import Assignments from './Assignments';

const Lesson = ({ match }) => {

    const { lesson, loading } = useSelector(({ lesson }) => lesson);

    const dispatch = useDispatch();
    const id = match.params.id;

    useEffect(() => {
        window.scrollTo(0, 0);
      dispatch(getLessonById(id))
    }, [lesson.title]);

    const onEnd = e => {  
      dispatch(markLessonCompleted(id))
    }

  return (
    loading || lesson.react_video === undefined ? <div className="single-lesson"> <SinglePostSkeleton/> <UpnextVideosSkeleton/> </div>  :

    <div className="single-lesson">
        <div className="lesson-container">
            <h3 className="lesson-title">{lesson?.title}</h3>
            <div className="instructor-container">
              <p className="lesson-instructor">@{lesson.instructor_name} </p>
              <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            { lesson?.assignments ?
             <Assignments/>

              :

            <div className="video-wrapper">
              <div className='player-wrapper'>
                <Vimeo
                  video={lesson?.react_video}
                  autoplay
                  start={lesson?.current_time}
                  responsive={true}
                  onEnd={e => onEnd(e)}
                  onTimeUpdate={(t) => console.log(t)}
                />
                <p className="lesson-description"> {lesson?.lesson_description} </p>  
              </div>
            </div>

            }
        </div>
        <div className="next-course-videos">
          <CourseNextVideos lesson={id} />
        </div>
     </div>        
  );
}

export default Lesson;
