import React from 'react';
import { Skeleton } from '@material-ui/lab';
import './skeleton.css';

const SinglePostSkeleton = () => {
  return (
        <div className="lesson-container">
            <div className="skeleton-text">
             <Skeleton variant="text" animation="wave" height={25}/>
            </div>
            <div className="instructor-container">
            <Skeleton variant="text" animation="wave" width={325} height={25} />
            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            <div className="skeleton-video-wrapper">
            <div className='player-wrapper'>
                <Skeleton variant="rect" animation="wave" height={520} />
                <Skeleton variant="text" animation="wave" height={25} />
                <Skeleton variant="text" animation="wave" height={25} />
                <Skeleton variant="text" animation="wave" height={25} />           
            </div>
            </div>
        </div>
  );
}

export default SinglePostSkeleton;
