import {  
   GET_COURSE_LESSONS,
   GET_COURSE_LESSONS_FAILED,
   GET_COURSE_LESSONS_REQUEST,
   MARK_LESSON_COMPLETED_SUCCESS,
   MARK_LESSON_COMPLETED_FAILED,
   SET_COURSE_ID_SUCCESS,
} from "../actions/types";

const initialState = {
    lessons: [],
    loading: false,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_COURSE_LESSONS:
            return {
                ...state,
                lessons: [...payload],
                loading: false
            }
        case GET_COURSE_LESSONS_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MARK_LESSON_COMPLETED_SUCCESS: 
            return {
                ...state,
                success: true
            }
        case MARK_LESSON_COMPLETED_FAILED:
            return {
                ...state,
                loading: false
            }
        default: 
            return state;
    }
};