import { SET_COURSE_ID_FAILED, SET_COURSE_ID_SUCCESS } from "../actions/types";

const initialState = {
    courseId: '',
    loading: false
}

export default function (state = initialState, action ) {
    const { type, payload } = action;

    switch(type) {
        case SET_COURSE_ID_SUCCESS:
            return {
                ...state,
                courseId: payload,
                loading: false
            }
        case SET_COURSE_ID_FAILED: 
            return {
                ...state,
                courseId: '',
                loading: false
            }
        default: 
            return state;
    }
}