import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentPage, getPostsByCategory } from '../actions/posts';
import CategorySelector from './CategorySelector';
import Pagination from './Pagination';
import WorkspaceSkeleton from './skeleton/WorkspaceSkeleton';



const Posts = () => {
    const { token } = useSelector(({ auth }) => auth);
    const { posts, loading } = useSelector(({ posts }) => posts);
    const { currentPage, postsPerPage } = useSelector(({ pagination }) => pagination);

    const [categoryOpen, setCategoryOpen ] = useState(false);
     const [selectCategory, setSelectCategory ] = useState('');
    

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPostsByCategory(selectCategory));
    }, [token, selectCategory]);

    const openCategories = () => {
        setCategoryOpen(true);
        window.scrollTo(0, 0);
    }

    // Change page
    const paginate = (number) => {
        dispatch(setCurrentPage(number));
        window.scrollTo(0, 550);
    } 

     // Set rendered posts
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = posts?.slice(indexOfFirstPost, indexOfLastPost)

    const getLinkForPost = (post) => {
        if(post.course_trailer) return `course/${post.id}`
        if(post.formula) return `formula/${post.id}`
        return `lesson/${post.id}`
    }


    return (
         loading ? <WorkspaceSkeleton/> :
         <Fragment>
             <CategorySelector categoryOpen={categoryOpen} setCategoryOpen={setCategoryOpen} setSelectCategory={setSelectCategory}/>
             <div onClick={() =>  openCategories()} className="selected-categories">
                 <p className="category-selector">All Categories</p>
                 <img src="https://dev.haircation.com/wp-content/plugins/posts-list//public/images/chevron-down.svg" alt="" className="selector-image" />
             </div>
                <div className="all-posts">
                    { currentPosts?.map(post => (
                           post.available ?
                           <Link key={post.id} className="link-wrapper" to={getLinkForPost(post)}> 
                                <div  className="post-container">
                                    <div className="thumbnail-container">
                                    <img className="post-thumbnail" src={post.course_trailer ? post.featured_image : post.app_thumbnail} alt="" />
                                    </div>
                                    <div className="post-meta">
                                        <p className="post-title">{post.title}</p>
                                        <div className="author-meta">
                                            <div className="author-container">
                                                <p className="post-teacher">@{post.instructor_name}</p>
                                                <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                                            </div>
                                            <p className="post-views">Views: {post.views}</p>
                                        </div>
                                    </div>
                                </div>           
                            </Link>
                        :
                            <div key={post.id} className="unavailable-post-container">
                                <div className="unavailable-thumbnail-container">
                                <img className="post-thumbnail" src={post.course_trailer ? post.featured_image : post.app_thumbnail} alt="" />
                                </div>
                                <div className="unavailable-post-meta">
                                    <p className="unavailable-post-title">{post.title}</p>
                                    <div className="unavailable-author-meta">
                                       <Link className="unavailable-post-purchase" to={`/purchase/${post.id}`}>
                                        <div className="unavailable-meta-container">
                                        <p className="unavailable-post-buy">BUY</p>
                                                <div className="unavailable-author-container">
                                                    <p className="unavailable-author">{post.instructor_name}</p>
                                                    <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                                                </div>
                                                <p className="unavailable-price">${post.price}</p>
                                        </div>
                                       </Link>
                                    </div>
                                </div>
                            </div>           
                        
                    ))
                    }  
                </div>
                <Pagination 
                    postsPerPage={postsPerPage} 
                    totalPosts={posts?.length} 
                    paginate={paginate}  
                />
            </Fragment>
       
    )
    
};

export default Posts;