import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getSinglePost } from '../actions/posts';
import { markLessonCompleted } from '../actions/courseLessons';
import Vimeo from '@u-wave/react-vimeo';
import SinglePostSkeleton from './skeleton/SinglePostSkeleton';
import UpnextVideosSkeleton from './skeleton/UpnextVideosSkeleton';
import UpnextVideos from './UpnextVideos';
import './SinglePost.css'

const SinglePost = ({ match }) => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { lesson, loading } = useSelector(({ singlePost }) => singlePost);

    const dispatch = useDispatch()
    const id = match.params.id;
    console.log(id);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSinglePost(id));
    }, [lesson.title]);

    const onEnd = e => {  
      dispatch(markLessonCompleted(id))
    }

    if(!user_id) {
      return <Redirect to='/workspace' />;
    }

  return (
    loading ? <div className="single-lesson"> <SinglePostSkeleton/> <UpnextVideosSkeleton/> </div>  :

      <div className="single-lesson">
        <div className="lesson-container">
            <h3 className="lesson-title">{lesson.title}</h3>
            <div className="instructor-container">
              <p className="lesson-instructor">@{lesson.instructor_name} </p>
              <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            <div className="video-wrapper">
              <div className='player-wrapper'>
                <Vimeo
                  video={lesson.react_video}
                  autoplay
                  start={lesson.current_time}
                  responsive={true}
                  onEnd={e => onEnd(e)}
                  onTimeUpdate={(t) => console.log(t)}
                />
                <p className="lesson-description"> {lesson.lesson_description} </p>  
              </div>
            </div>
        </div>
        <UpnextVideos
          id={id}
        />
     </div>        
   
  );
}

export default SinglePost;
