import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import Menu from './Menu';
import './navbar.css';


const Navbar = () => {
    const { token } = useSelector(({ auth }) => auth);
    const [open, setOpen ] = useState(false);
    
    const preventDefault = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if(open) {
            document.body.style.overflowY = 'hidden';
            document.body.addEventListener('touchmove', preventDefault, { passive: false });
           
        }
        return () =>{
            document.body.style.overflowY = 'auto';
            document.body.removeEventListener('touchmove', preventDefault);
            
        }
      }, [open])
   
    return (
        <div className={open ? 'menu-container-active nav-uni' : 'menu-container nav-uni'}>
            <Link 
                onClick={() => setOpen(false)} 
               to={token ? '/workspace' : '/'}
               >
                <img className={open ? 'logo white' : 'logo'} 
                 src="https://haircation.com/wp-content/uploads/2021/07/White-logo.png" alt="" />
                </Link>
             <Hamburger rounded toggled={open} toggle={setOpen} size={35} direction='left' color={open ? '#ffffff' : '#000000'}/>
            <Menu open={open} setOpen={setOpen} />
        </div>
    )
};

export default Navbar;