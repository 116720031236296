import { CREATE_MEMBERSHIP_SUCCESS, CREATE_MEMBERSHIP_REQUEST, CREATE_MEMBERSHIP_FAILED, PURCHASE_POST_SUCCESS, PURCHASE_POST_REQUEST, PURCHASE_POST_FAILED } from "../actions/types";

const initialState = {
    registration: {},
    loading: false,
    purchasePost: false
}

export default function ( state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CREATE_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                registration: payload,
                loading: false
            }
        case CREATE_MEMBERSHIP_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case CREATE_MEMBERSHIP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PURCHASE_POST_SUCCESS:
            return {
                ...state,
                purchasePost: true,
                loading: false
            }
        case PURCHASE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PURCHASE_POST_FAILED:
            return {
                ...state,
                purchasePost: false,
                loading: false

            }
        default: 
            return state;
    }
}