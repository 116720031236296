import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentPage, previewPostsByCategory } from '../actions/posts';
import CategorySelector from './CategorySelector';
import Pagination from './Pagination';
import WorkspaceSkeleton from './skeleton/WorkspaceSkeleton';
import formula from '../assets/formula.png';

const Posts = () => {
    const { token } = useSelector(({ auth }) => auth);
    const { posts, loading } = useSelector(({ posts }) => posts);
    const { currentPage, postsPerPage } = useSelector(({ pagination }) => pagination);

    const [categoryOpen, setCategoryOpen ] = useState(false);
     const [selectCategory, setSelectCategory ] = useState('');
     console.log(selectCategory);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(previewPostsByCategory(selectCategory));
    }, [token, selectCategory]);

    // Change page
    const paginate = (number) => {
        window.scrollTo(0, 550);  
        dispatch(setCurrentPage(number));
    } 

     // Set rendered posts
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = posts?.slice(indexOfFirstPost, indexOfLastPost)

    const getLinkForPost = (post) => {
        if(post.course_trailer) return `course/${post.id}`
        if(post.formula) return `formula/${post.id}`
        return `lesson/${post.id}`
    }


    return (
         loading ? <WorkspaceSkeleton/> :
         <Fragment>
                <div className="all-posts">
                    { currentPosts?.map(post => (
                        <Link class="preview-link-wrapper" to={`/purchase/${post.id}`}  >
                            <div key={post.id} className="unavailable-post-container">
                                <div className="unavailable-thumbnail-container">
                                <img className="post-thumbnail" src={post.course_trailer ? post.featured_image : post.app_thumbnail} alt="" />
                                </div>
                             <div className="unavailable-post-meta">
                                        { post.formula_label ? 
                                        <div className="title-container">
                                            <img src={formula} alt="" className="formula-logo" />
                                            <p className="unavailable-post-title">{post.title}</p>
                                        </div>
                                         : 
                                        <p className="unavailable-post-title">{post.title}</p>
                                        }
                                        <div className="unavailable-author-meta">
                                            <p  className="unavailable-post-buy" >BUY</p>
                                            <div className="unavailable-meta-container">
                                                <div className="unavailable-author-container">
                                                    <p className="unavailable-author">{post.instructor_name}</p>
                                                    <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                                                </div>
                                                <p className="unavailable-price">${post.price}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>                
                        </Link>
                    ))
                    }  
                </div>
                <Pagination 
                    postsPerPage={postsPerPage} 
                    totalPosts={posts?.length} 
                    paginate={paginate}  
                />
            </Fragment>
       
    )
    
};

export default Posts;