import React, { Fragment } from 'react';
import Faq from "react-faq-component";


const FAQs = () => {

    const data = {
        rows: [
            {
                title: "Is there contracts with Haircation?",
                content: "Nope! You can come learn as much as you want, cancel, take a break from us and come back whenever you'd like."
            },
            {
                title: "Is the training content released over time?",
                content: "Nope! You can come learn as much as you want, cancel, take a break from us and come back whenever you'd like."
            },
            {
                title: "How often will videos be uploaded?",
                content: "Haircation is committed to adding new content weekly. A new tutorial will be released every Monday. Haircation is a place where everyone can share, learn and grow. Habit stylists will share their tips, tricks, and secrets. We cannot wait to connect and learn from each of you."
            },
            {
                title: "What is the difference between videos and courses?",
                content: "We have courses created to cover a specific topic in detail and with actionable instruction you can use it on clients right away."
            },
            {
                title: "How can I receive support?",
                content: "Our live chat support is here for you, Monday – Friday / 9am – 5pm to help you with anything you need."
            },
            {
                title: "What is the difference between videos and courses?",
                content: "We have courses created to cover a specific topic in detail and with actionable instruction you can use it on clients right away."
            },
            {
                title: "What is the salon locator?",
                content: "As a member, you can submit your salon information to be placed on a map where other stylists and clients can find you from around the world. Thousands of people come to our site monthly to search stylists by their zip code. This is a perfect way to advertise yourself and increase your revenue. "
            },
        ]
    }

    const styles = {
        // bgColor: 'white',
        titleTextColor: "black",
        rowTitleColor: "black",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const config = {
        animate: true,
        arrowIcon: '+',
        tabFocus: true
    };

    return (
        <Fragment>
            <div className="faq-image-container">
                <img className="faq-image" src="https://haircation.com/wp-content/uploads/2022/02/questions.png" alt=""  />
            </div>
            <div className="faq-container">
                <Faq
                data={data}
                styles={styles}
                config={config}
                />
            </div>
        </Fragment>
    )
}

export default FAQs;