import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const Brittany = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    if(!user_id) {
        return <Redirect to='/workspace' />;
    }
    
  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2020/02/Bryanna-M.jpg" alt="Bryanna Mariscal" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Bryanna Mariscal</h1>    
                            <p className="mentor-about">Hi! My name is Bryanna!
                            I am a hairstylist at Habit Salon. I have worked for Habit Salon for 2 years now. I am from a super small town in New Mexico named Deming. 
                            I have lived in Phoenix for 6 years now and it is the place I call home! 
                            Hair has always been my passion! When I’m not at the salon you’ll find me shopping, at the gym or hanging out at home with my dog Nayla!
                            I am so excited to meet all of you and looking forward to sharing my love for the Habit Extension Method with you!
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/6176621880?pwd=akg5YXpmalBpbWVFeUg2aFJYbXlYQT09" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default Brittany;
