import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const Ali = () => {
    
    const { user_id } = useSelector(({ auth }) => auth);
    if(!user_id) {
       return <Redirect to='/workspace' />;
     }

     
  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2020/01/Ali-G.jpg" alt="Ali Gallego" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name"> Ali Gallego</h1>    
                            <p className="mentor-about">Hi my name is Ali Gallego. 
                            I’m based in California and Arizona! Desert girl at heart but gotta love the quick and busy world LA gives.  
                            I’ve been doing hair at Habit for 3.5 years and I couldn’t be more into this amazing career! 
                            I always feel so lucky and excited to go to work because as hairstylist we get to do art every single day! 
                            My expertise are in color & extensions, especially love my color corrections. 
                            I feel extensions are the cherry on top to any color transformation! If you want to check out my work <a href="https://instagram.com/aligallego_hair" target="_blank">@aligallego_hair</a>  
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/5358022675?pwd=akVQUVZibXFkMGJQampmTUgwQ0hJUT09" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default Ali;
