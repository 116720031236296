import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPosts } from '../actions/posts';



const UpnextVideos = ({ id }) => {
    const { token } = useSelector(({ auth }) => auth);
    const { posts } = useSelector(({ posts }) => posts);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPosts(token));
    }, [posts.title]);

    // const nextVideos = posts?.map((videos) => {
    //     let foundIndex = Object.entries(videos)
    //     console.log(foundIndex);
    // })

    console.log(posts);


  return (
    <div className="upnext-videos">
        <h1 className="upnext">Up next</h1>
        { posts?.map((post) => (
          
          post.available ? 
            <div key={post.id} className="video-container">
                <div className="upnext-thumbnail-container">
                <Link to={ `/lesson/${post.id}` }>
                    <img className="upnext-thumbnail" src={post.app_thumbnail} alt="" />
                </Link>
                </div>
                <div className="upnext-meta">
                <Link className="title-link" to={`/lesson/${post.id}`}>
                    <h3 className="upnext-title">{post.title}</h3>
                </Link>
                <div className="instructor-container">
                <p className="lesson-instructor">@{post.instructor_name} </p>
                <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                </div>
                </div>
            </div>
            :
            <div key={post.id} className="video-container">
                <div className="upnext-thumbnail-container">
                    <Link to={ `/purchase/${post.id}` }>
                        <img className="upnext-thumbnail" src={post.app_thumbnail} alt="" />
                    </Link>
                </div>
                <div className="upnext-meta">
                    <Link className="title-link" to={`/purchase/${post.id}`}>
                        <h3 className="upnext-title">{post.title}</h3>
                    </Link>
                    <div className="unavailable-instructor-container">
                        <div className="instructor-meta">
                            <p className="lesson-instructor">@{post.instructor_name} </p>
                            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                        </div>
                        <Link className="upnext-unavailable-post-buy" to={`/purchase/${post.id}`}>BUY</Link>
                    </div>
                </div>
            </div>
        ))
        }       
  </div>
  );
}

export default UpnextVideos;
