import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './profile.css';


const Profile = () => {
    const { currentUser } = useSelector(({ member }) => member);
    const { user_id } = useSelector(({ auth }) => auth);

  return (
    <div className="profile-container">
      <p className="profile-name">Welcome {currentUser.display_name}</p>
      <ul className="profile-details">
          <Link className="profile-items" to={`/account/${user_id}`}>Account Details</Link>
          <Link className="profile-items" to={`/subscriptions/${user_id}`}>Subscription(s)</Link>
          <Link className="profile-items" to={`/payments/${user_id}`}>Payments</Link>
          <Link className="profile-logout" to='#'>Logout</Link>
      </ul>
    </div>
  );
}

export default Profile;
