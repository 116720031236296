import http from '../utils/http';
import { GET_POSTS, 
    GET_POSTS_REQUEST, 
    GET_POSTS_FAILED, 
    GET_SINGLE_POST, 
    GET_SINGLE_POST_REQUEST, 
    GET_SINGLE_POST_FAILED,
    SET_CURRENT_PAGE,
    SET_CURRENT_PAGE_FAILED,
    GET_POSTS_PREVIEW_REQUEST,
    GET_POSTS_PREVIEW_SUCCESS,
    GET_POSTS_PREVIEW_FAILED
} from './types';

export const getPosts = () => async dispatch => {
    
        try {
            dispatch({ type: GET_POSTS_REQUEST});
            const res = await http.get('wp/v2/custom/access/posts');
            dispatch({
                type: GET_POSTS,
                payload: res.data
            })
        } catch (err) {
            console.log(err);
            dispatch({
                type: GET_POSTS_FAILED
            })
        }
    
}

export const previewPostsByCategory = (postID) => async dispatch => {
    try {
        dispatch ({ type: GET_POSTS_PREVIEW_REQUEST});
        const res = await http.get(`wp/v2/custom/preview/posts${postID}`);
        dispatch({
            type: GET_POSTS_PREVIEW_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_POSTS_PREVIEW_FAILED
        })
    }
}

export const getPostsByCategory = (postID) => async dispatch => {
    
        try {
            dispatch({ type: GET_POSTS_REQUEST});
            const res = await http.get(`wp/v2/custom/access/posts${postID}`);
            dispatch({
                type: GET_POSTS,
                payload: res.data
            })
        } catch (err) {
            console.log(err);
            dispatch({
                type: GET_POSTS_FAILED
            })
        }
    
}

export const getSinglePost = (id) => async dispatch => {
  
    try {
        dispatch({type: GET_SINGLE_POST_REQUEST});
        const res = await http.get(`wp/v2/custom/post/${id}`);
        dispatch({
            type: GET_SINGLE_POST,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_SINGLE_POST_FAILED
        });
    }
}

// This is for the pagination of the posts
export const setCurrentPage = (number) => async dispatch => {
   
    try {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: number
        })

      
    } catch (err) {
        console.log(err);
        dispatch({ SET_CURRENT_PAGE_FAILED })
    }
}