import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const ElliG = () => {

 const { user_id } = useSelector(({ auth }) => auth);
 if(!user_id) {
    return <Redirect to='/workspace' />;
  }


  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2022/08/Image-from-iOS.jpg" alt="Elli Guzzo" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Elli Guzzo</h1>    
                            <p className="mentor-about">
                                Hi! I'm Elli Guzzo and I can't wait to meet all of you and be your Habit Extension Mentor!
                                I love helping others and being able to teach something I love means a lot to me! 
                                I have been in the hair industry for 4 years and have been working at Habit salon for 2 years! 
                                I was born and raised in Minnesota. If I'm not at the salon you can find me at the gym, shopping, going to hockey games and hanging with friends!  
                                Follow me on Instagram @hairbyelli_ so we can get to know each other.
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/5627854498?pwd=q7AnBBSrxCQK6ObF2de3WELeaSHlrD.1" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default ElliG;
