import React from 'react';

const Completed = ({ link }) => {
  return (
    <a href={ link } className="play-icon">
       <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="8" fill="#39CB9F"/>
            <path d="M9 12.0556L11.5397 14L15.6667 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </a>
  );
}

export default Completed;
