import { GET_CONTENT_CATEGORIES_FAILED, GET_CONTENT_CATEGORIES_REQUEST, GET_CONTENT_CATEGORIES_SUCCESS } from "../actions/types";

const initialState = {
    categories: [],
    loading: false
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_CONTENT_CATEGORIES_SUCCESS:
            return {
                ...state, 
                categories: payload,
                loading: false
            }
        case GET_CONTENT_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_CONTENT_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        default: 
            return state;
    }
}