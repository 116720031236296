import http from '../utils/http';
import { 
    CREATE_MEMBERSHIP_SUCCESS, 
    CREATE_MEMBERSHIP_FAILED, 
    CREATE_MEMBERSHIP_REQUEST, 
    PURCHASE_POST_FAILED, 
    PURCHASE_POST_REQUEST, 
    PURCHASE_POST_SUCCESS ,
} from "./types"


export const createMembership = (formData, id) => async dispatch => {

    try {
        dispatch({ type: CREATE_MEMBERSHIP_REQUEST });
        const res = await http.post(`wp/v2/custom/membership/create/${id}`, formData);
        dispatch({
            type: CREATE_MEMBERSHIP_SUCCESS,
            payload: res.data   
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: CREATE_MEMBERSHIP_FAILED
        });
    }
};

export const completeSinglePurchase = (formData, id) => async dispatch => {
    try {
        
        dispatch({ type: PURCHASE_POST_REQUEST });
        const res = await http.post(`wp/v2/custom/purchase?post=${id}`, formData);

        dispatch({
            type: PURCHASE_POST_SUCCESS,
            payload: res.data
        });

        
    } catch (err) {
        console.log(err);
        dispatch({
            type: PURCHASE_POST_FAILED
        });
    }
}