export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_FAILED = 'GET_MEMBER_FAILED';
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_POSTS_PREVIEW_SUCCESS = 'GET_POSTS_PREVIEW';
export const GET_POSTS_PREVIEW_REQUEST = 'GET_POSTS_PREVIEW_REQUEST';
export const GET_POSTS_PREVIEW_FAILED = 'GET_POSTS_PREVIEW_FAILED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_REQUEST = 'GET_POST_REQUEST';
export const GET_POSTS_FAILED = 'GET_POSTS_FAILED';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const GET_SINGLE_POST_REQUEST = 'GET_SINGLE_POST_REQUEST';
export const GET_SINGLE_POST_FAILED = 'GET_SINGLE_POST_FAILED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_PAGE_FAILED = 'SET_CURRENT_PAGE_FAILED';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = ' REMOVE_ERROR_MESSAGE';
export const GET_COURSE_LESSONS = 'GET_COURSE_LESSONS';
export const GET_COURSE_LESSONS_REQUEST = 'GET_COURSE_LESSONS_REQUEST';
export const GET_COURSE_LESSONS_FAILED = 'GET_COURSE_LESSONS_FAILED';
export const GET_COURSE_LESSON = 'GET_COURSE_LESSON';
export const GET_COURSE_LESSON_REQUEST = 'GET_COURSE_LESSON_REQUEST';
export const GET_COURSE_LESSON_FAILED = 'GET_COURSE_LESSON_FAILED';
export const SET_COURSE_ID_SUCCESS = 'SET_COURSE_ID_SUCCESS';
export const SET_COURSE_ID_FAILED = 'SET_COURSE_ID_FAILED';
export const GET_CONTENT_CATEGORIES_SUCCESS = 'GET_CONTENT_CATEGORIES_SUCCESS';
export const GET_CONTENT_CATEGORIES_REQUEST = 'GET_CONTENT_CATEGORIES_REQUEST';
export const GET_CONTENT_CATEGORIES_FAILED = 'GET_CONTENT_CATEGORIES_FAILED';
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_REQUEST = 'GET_MEMBERSHIP_REQUEST';
export const GET_MEMBERSHIP_FAILED = 'GET_MEMBERSHIP_FAILED';
export const CANCEL_MEMBERSHIP_FAILED = 'CANCEL_MEMBERSHIP_FAILED';
export const CANCEL_MEMBERSHIP_SUCCESS = 'CANCEL_MEMBERSHIP_SUCCESS';
export const CANCEL_MEMBERSHIP_REQUEST = 'CANCEL_MEMBERSHIP_REQUEST';
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBERSHIP_FAILED = 'CREATE_MEMBER_FAILED';
export const CREATE_MEMBERSHIP_REQUEST = 'CREATE_MEMBER_REQUEST';
export const MARK_LESSON_COMPLETED_SUCCESS = 'MARK_LESSON_COMPLETED_SUCCESS';
export const MARK_LESSON_COMPLETED_FAILED = 'MARK_LESSON_COMPLETED_FAILED';
export const GET_MEMBER_PROFILE_SUCCESS = 'GET_MEMBER_PROFILE_SUCCESS';
export const GET_MEMBER_PROFILE_FAILED = 'GET_MEMBER_PROFILE_FAILED';
export const GET_MEMBER_PROFILE_REQUEST = 'GET_MEMBER_PROFILE_REQUEST';
export const PURCHASE_POST_SUCCESS = 'PURCHASE_POST_SUCCESS';
export const PURCHASE_POST_FAILED = 'PURCHASE_POST_FAILED';
export const PURCHASE_POST_REQUEST = 'PURCHASE_POST_REQUEST';
export const SET_NEXT_VIDEOS = 'SET_NEXT_VIDEOS';
export const _SET_NEXT_VIDEOS_FAILED = 'SET_NEXT_VIDEOS_FAILED';