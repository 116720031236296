import React from 'react';
import './one.css'
const OneOnOne = () => {
  return (
    <div className="one-container">
      <h1 className="one-title">One On One Extension Certification</h1>
      <hr className="one-divider"/>
      <p className="one-price">$3500</p>
      <div className="one-btn-container">
        <a target="_blank" href="https://habitshadow.as.me/oneonone" className="one-btn hide-d">Schedule Now</a>
      </div>
      <div className="one-meta-container">
          <div className="one-column-1">
            <p className="one-description">
                One On One Training is a more immersive experience than our traditional in-person group classes. The training lasts a total of 6 hours with the first half shadowing Chrissy and her team members. 
                During this time you will be able to watch and experience all of Chrissy’s methods, practices, and techniques that she uses everyday.
                On any given day Chrissy has  10-25 clients giving you the opportunity to learn and absorb a lot of information. Questions are always welcomed during the entire day. 
                The second half will include a private session with one of Chrissy’s extension stylists. Here you will practice the Habit Extension Method on a mannequin. Your instructor will walk you 
                through each step of applying each row of extension on the mannequins head.
                <br/>
                <br/>
                After this training you will have access to our private Habit Extension Attendees Facebook page where you will receive continued support from our instructors and others that have attended our extension classes.  
                Participants of this training will also receive a free 30-day trial of our online education. With special access to our extension method follow-up videos; which will give you a reference to base your future work off of
                as you practice extensions on your clients.
            </p>
            <div className="one-btn-container">
             <a target="_blank" href="https://habitshadow.as.me/oneonone" className="one-btn">Schedule Now</a>
            </div>
          </div>
          <img src="https://haircation.com/wp-content/uploads/2019/09/1on1-683x1024-683x1024.jpg" alt="" className="model-image" />
      </div>
    </div>
  );
}

export default OneOnOne;
