import React from 'react';
import { Skeleton } from '@material-ui/lab';


const WorkspaceSkeleton = () => {
  return (
    <div className="all-posts">
            <div  className="post-container">
                <div className="thumbnail-container">
                <Skeleton variant="rect" height={520} />
                </div>
                <div className="post-meta">
                     <Skeleton variant="text" height={25} />
                    <div className="author-meta">
                        <div className="author-container">
                           <Skeleton variant="text" width={125} height={25} />
                            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                        </div>
                        <Skeleton variant="text" width={50} height={25} />
                    </div>
                </div>
            </div>                 
            <div  className="post-container">
                <div className="thumbnail-container">
                <Skeleton variant="rect" height={520} />
                </div>
                <div className="post-meta">
                     <Skeleton variant="text" height={25} />
                    <div className="author-meta">
                        <div className="author-container">
                           <Skeleton variant="text" width={125} height={25} />
                            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                        </div>
                        <Skeleton variant="text" width={50} height={25} />
                    </div>
                </div>
            </div>                 
            <div  className="post-container">
                <div className="thumbnail-container">
                <Skeleton variant="rect" height={520} />
                </div>
                <div className="post-meta">
                     <Skeleton variant="text" height={25} />
                    <div className="author-meta">
                        <div className="author-container">
                           <Skeleton variant="text" width={125} height={25} />
                            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                        </div>
                        <Skeleton variant="text" width={50} height={25} />
                    </div>
                </div>
            </div>                 
            <div  className="post-container">
                <div className="thumbnail-container">
                <Skeleton variant="rect" height={520} />
                </div>
                <div className="post-meta">
                     <Skeleton variant="text" height={25} />
                    <div className="author-meta">
                        <div className="author-container">
                           <Skeleton variant="text" width={125} height={25} />
                            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
                        </div>
                        <Skeleton variant="text" width={50} height={25} />
                    </div>
                </div>
            </div>                 
    </div>
  );
}

export default WorkspaceSkeleton;
