import React from 'react';
import './discounts.css'

const Discount = () => {
  return (
    <div className="discount-container">
      <h1 className="discount-title">Haircation Discounts</h1>
      <p className="discount-subheader">Discounts on popular tools & suppliers</p>

     <div className="discount-card-container">
         <a className="discount-link" target="_blank" href="https://app.forwardforms.com/create-account?discountCode=haircation" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image" src="https://haircation.com/wp-content/uploads/2020/11/150x150bb.png" alt="" />
                </div>
                <p className="discount-brand-title">Forward Forms - $10/mo off</p>
                <p className="discount-brand-description">The easiest way to connect with new clients – Virtual Consultations for Stylists</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://gnmsalonapparel.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image" src="https://haircation.com/wp-content/uploads/2020/11/salongm150.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Salon G&M – 25% Off</p>
                <p className="discount-brand-description">Ultra premium custom salon wear. Use code: HAIRCATION25</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://www.standishsalongoods.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image" src="https://haircation.com/wp-content/uploads/2020/11/salon-standish.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Salon Standish – 15% Off</p>
                <p className="discount-brand-description">Salon goods for salon owners. Use code: HAIRCATION15</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://sheilastotts.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://haircation.com/wp-content/uploads/2021/01/sHIELA-1.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Salon Standish – 15% Off</p>
                <p className="discount-brand-description">Salon goods for salon owners. Use code: HAIRCATION15</p>
            </div>
         </a>
         
         <a className="discount-link" target="_blank" href="https://hairhearts.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://haircation.com/wp-content/uploads/2021/01/Hair-Hearts.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Hair Hearts – 30% Off</p>
                <p className="discount-brand-description">Hair vitamins that empower women & men to have healthier and stronger hair. Use code: HAIRCATION</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://www.doctoredlocks.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://haircation.com/wp-content/uploads/2021/03/dl.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Doctored Locks – 25% off your first order</p>
                <p className="discount-brand-description">With purchase of Bliss Hand Tied Hair – limit 1 use per customer.  Use Code BLISS25</p>
            </div>
         </a>
         
         <a className="discount-link" target="_blank" href="https://haircation.com/wp-content/uploads/2021/03/dl.jpg" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://haircation.com/wp-content/uploads/2021/03/dl.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Doctored Locks – Free magnetic bracelet</p>
                <p className="discount-brand-description"> Buy 2 jars of Linkies and get a magnetic bracelet free (bracelet needs to be in the cart with the Linkies when it’s applied.) Use Code: MAGNETIC</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://www.doctoredlocks.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://haircation.com/wp-content/uploads/2021/03/dl.jpg" alt="" />
                </div>
                <p className="discount-brand-title">Doctored Locks – Free weaving thead</p>
                <p className="discount-brand-description">Buy any jar of Linkies and get a spool of original weaving thread for free (thread needs to be in cart with the Linkies when it’s applied.) Use Code: FREETHREAD</p>
            </div>
         </a>

         <a className="discount-link" target="_blank" href="https://habithairx.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image resize-image" src="https://habithairx.com/wp-content/uploads/2020/11/HabitLogo_Black-ehp-300x220.png" alt="" />
                </div>
                <p className="discount-brand-title">Habit Hair X – up to 50% off all hair</p>
                <p className="discount-brand-description">Click the image and register as a stylist</p>
            </div>
          </a>

         <a className="discount-link" target="_blank" href="https://www.marlobeauty.com/" >
            <div className="discount-card">
                <div className="discount-card-image-container">
                    <img className="discount-brand-image" src="https://haircation.com/wp-content/uploads/2021/09/marlo-beauty.png" alt="" />
                </div>
                <p className="discount-brand-title">Marlo Beauty – Use code 5857 upon registration</p>
                <p className="discount-brand-description">$10 off first time orders over $100</p>
            </div>
          </a>
     </div>

    </div>
  );
}

export default Discount;
