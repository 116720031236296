import React from 'react';
import './locator.css';

const Locator = () => {
    (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://cdn.storepoint.co/api/v1/js/15f98b46928e49.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b);}());

  return (
    <div className="locator-container">
      <h1 className="locator-title">Find a stylist near you</h1>
      <p className="locator-description">*All Stylists shown on the Salon Locator are Haircation Members and may also have attended one of our Hands-On Certification courses. The members listed on the salon locator are NOT affiliated,  or employeed/endorsed by Habit Salon and @hairby_chrissy*</p>
      <img src="https://haircation.com/wp-content/uploads/2020/03/salon-locator-key.jpg" alt="" className="locator-key" />
      <div id="storepoint-container" data-map-id="15f98b46928e49"></div>
    </div>
  );
}

export default Locator;
