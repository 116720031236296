import {  GET_SINGLE_POST, GET_SINGLE_POST_FAILED, GET_SINGLE_POST_REQUEST } from "../actions/types";

const initialState = { 
    lesson: {},
    loading: false
}

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    
    switch(type) {
        case GET_SINGLE_POST:
            return {
                lesson: {...payload},
                loading: false
            }
        case GET_SINGLE_POST_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_SINGLE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        
        default: 
            return state;
    }
};