import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const Brittany = () => {
    const { user_id } = useSelector(({ auth }) => auth);

    if(!user_id) {
        return <Redirect to='/workspace' />;
    }

  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2020/01/IMG-0007.jpg" alt="Brittany Hernandez" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Brittany Hernandez</h1>    
                            <p className="mentor-about">I want to share a little about myself and career background. I am an Arizona native who enjoys calling this beautiful place home. 
                            There’s nothing better than trips to Sedona, Flagstaff, and the local lakes with my two beautiful children and husband.  
                            I’ve been licensed for 15 year, and am passionate about sharing my experience with habit hand tied extensions, color, styling etc. 
                            I’m so excited for the chance to mentor you and can’t wait to watch you succeed and grow in your business. 
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/7065075800?pwd=YVFEem1QZ1pVVDVEQkpkbW1qSitwdz09" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default Brittany;
