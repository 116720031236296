import { combineReducers } from 'redux';
import auth from './auth';
import member from './member';
import posts from './posts';
import singlePost from './singlePost';
import pagination from './pagination';
import error from './error';
import courseLessons from './courseLessons';
import lesson from './courseLesson';
import category from './category';
import membership from './membership';
import registration from './registration';
import setCourseId from './setCourseId';

export default combineReducers({
    auth,
    member,
    posts,
    pagination,
    singlePost,
    error,
    courseLessons,
    lesson,
    category,
    membership,
    registration,
    setCourseId
});