import axios from 'axios'
import { GET_MEMBERSHIP_SUCCESS, GET_MEMBERSHIP_REQUEST, GET_MEMBERSHIP_FAILED, CANCEL_MEMBERSHIP_FAILED, CANCEL_MEMBERSHIP_REQUEST, CANCEL_MEMBERSHIP_SUCCESS } from "./types";

export const getMembership = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'MEMBERPRESS-API-KEY': 'JmLD3IBMCd'
        }
    }

    try {
        dispatch({ type: GET_MEMBERSHIP_REQUEST });

        const res = await axios.get(`https://haircation.com/wp-json/mp/v1/memberships/${id}`, config);
        dispatch({
            type: GET_MEMBERSHIP_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_MEMBERSHIP_FAILED
        })
    }
}

export const cancelMembership = (id, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'MEMBERPRESS-API-KEY': 'JmLD3IBMCd'
            
        }
    } 

    console.log(config);

    try {
        dispatch({ type: CANCEL_MEMBERSHIP_REQUEST});

        const res = await axios.post(`https://haircation.com/wp-json/mp/v1/subscriptions/${id}/cancel`, null, config);
        dispatch({
            type: CANCEL_MEMBERSHIP_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CANCEL_MEMBERSHIP_FAILED
        })
    }
}