import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglePost } from '../actions/posts';
import './formula.css'


const Formula = ({ match }) => {
    const { lesson, loading } = useSelector(({ singlePost }) => singlePost);
    
    const dispatch = useDispatch();
    const id = match.params.id;

    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(getSinglePost(id));
    }, [lesson.title]);

    return (
        loading ? <h1>Loading...</h1> :
    <div>
      <div dangerouslySetInnerHTML={{__html: lesson.formula}}></div>
    </div>
  );
}

export default Formula;
