import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './announcement.css';
import './swiper.css';
import axios from 'axios';

const Announcement = () => {
 const [ announcements, setAnnouncements] = useState([]);
 const [ loading, setLoading ] = useState([false]);

  const getAnnouncements = async ()  => {
    setLoading(true);
    const res = await axios.get('https://api.apispreadsheets.com/data/b6YR2YRqaLG4Eo1v/?accessKey=84ca911de987a66bd75bd514c2a95ead&secretKey=9c9ec44677c8036e7f0a5a0c34f7e8ab');
    setAnnouncements(res.data);
    setLoading(false);
  }

  console.log(announcements);
  
  useEffect(() => {
    getAnnouncements();
  }, [announcements[0]]);

  if(loading) {
    return (
      <div className="announcement-container">
              <p className="announcement-description">Loading...</p>
      </div>
    ) 
  }
  const allAnnouncements = announcements.data?.map( a => (
    <SwiperSlide>
      <div className="announcement-container">
        <h1 className="announcement-title">{a.announcement_title}</h1>
        <p className="announcement-description">{a.announcement_body}</p>
      </div>
    </SwiperSlide>
  ))


  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      pagination={{ clickable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {allAnnouncements}
    </Swiper>
  );
}

export default Announcement;
