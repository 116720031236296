import {
    SET_CURRENT_PAGE,
    SET_CURRENT_PAGE_FAILED

} from "../actions/types";

const initialState = {
    currentPage: 1,
    postsPerPage: 8,
    loading: false
}

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    
    switch(type) {
        case SET_CURRENT_PAGE:
            return {
                currentPage: payload,
                postsPerPage: 8,
                loading: false
            }
        case SET_CURRENT_PAGE_FAILED:
            return {
                ...state,
                loading: false
            }
        default: 
            return state;
    }
};