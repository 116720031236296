import React from 'react';
import { Link } from 'react-router-dom';
import Play from '../../icons/Play';
import Completed from '../../icons/Completed';



const AvailableLessons = ({ lesson }) => {
    return (
        <Link key={lesson.id} className="lesson-link" to={lesson.assignments ? `/course/assignment/${lesson.id}` : `/course/lesson/${lesson.id}`}>
            <div className="course-lesson-container">
                <h3 className="section-title"> {lesson.section_heading} </h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        {!lesson.completed_lesson ? <Play link={`/lesson/${lesson.id}`}/>  : <Completed link={`/lesson/${lesson.id}`}/>  }
                        
                        <p className="lesson-title">{lesson.title}</p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default AvailableLessons;