import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import './redeemYearly.css';

const RedeemYearly = () => {
    const { activeSubscription } = useSelector(({ member }) => member);
    const { token } = useSelector(({ auth }) => auth);
  
    const membershipArray = [217287, 251587, 243627, 226311, 214481, 248013, 250565, 249030, 248159, 238533, 214485, 214487, 214489]

    const checkMemberships = () => {
      
    }

    const getMembership = (id) => {
        var result = membershipArray.map(memId => {
         return memId === id
        })


    //  for (let ele of membershipArray) {
    //      if(ele === id) {
    //        console.log(ele);
    //      } else if(ele !== id) {
    //       (
    //         <div className="membership-banner" key={id}>
    //              <p className="upgrade-membership-text">Upgrade to yearly to unlock all videos and courses!</p>
    //           </div>
    //       );
    //      }
        
    //  }
    }
  return (
   
    <Fragment>
       { token ?
        activeSubscription?.map(membership => (
          getMembership(membership.id)
        ))
        : null
       }
        
    </Fragment>
  )
}

export default RedeemYearly;
