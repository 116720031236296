import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { login } from '../../actions/auth';
import ErrorMessage from '../errors/ErrorMessage';
import './login.css';


const Login = ({ location }) => {
    
    const { token, loading } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
  
   
    const [formData, setFormData ] = useState({
        username: '',
        password: ''
    });

    const { username, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        dispatch(login(username, password));
    }

    if (token) {
        return <Redirect to='/workspace' />;
      }


    return (
        <div className={`login-container ${location}`}>
            <h1 className="login-text">Have An Account? Login</h1>
            <ErrorMessage/>
            <form className="login-form" onSubmit={e => onSubmit(e)}>
                <input 
                type="text" 
                className="login-input"
                placeholder="Email Address"
                name="username"
                value={username}
                onChange={e => onChange(e)}
                
                />
                <input 
                type="password" 
                className="login-input" 
                placeholder="Type your password"
                name="password"
                value={password}
                onChange={e => onChange(e)}
                />
                <input className="form-submit" value={loading ? "Loading..." : "Login"} type="submit" />
            </form>

            <Link className="create-account-btn" to='/join/248013'>Create an account</Link>
        </div>
    )
};

export default Login;