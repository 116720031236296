import { GET_COURSE_LESSON, GET_COURSE_LESSON_FAILED, GET_COURSE_LESSON_REQUEST } from "../actions/types";

const initialState = {
    lesson: {},
    loading: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_COURSE_LESSON:
            return {
                ...state,
                lesson: payload,
                loading: false
            }
        case GET_COURSE_LESSON_FAILED:
            return {
                ...state,
                loading: false
            }
        case GET_COURSE_LESSON_REQUEST:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}