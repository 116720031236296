import React from 'react';
import { Skeleton } from '@material-ui/lab';
import './mentorSkeleton.css';


const LessonSkeleton = () => {

  return (
    <div className="skeleton-lesson-container">
        <h1 className="skeleton-header"> <Skeleton height={25} /></h1>
            <div className="course-lesson-container">
                <h3 className="section-title"> <Skeleton height={25} /></h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        <Skeleton variant="rectangle" height={30} width={30} />
                    </div>
                    <div className="skeleton-title"> <Skeleton height={25} /> </div>
                </div>
            </div>

            <div className="course-lesson-container">
                <h3 className="section-title"> <Skeleton height={25} /></h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        <Skeleton variant="rectangle" height={30} width={30} />
                    </div>
                    <div className="skeleton-title"> <Skeleton height={25} /> </div>
                </div>
            </div>

            <div className="course-lesson-container">
                <h3 className="section-title"> <Skeleton height={25} /></h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        <Skeleton variant="rectangle" height={30} width={30} />
                    </div>
                    <div className="skeleton-title"> <Skeleton height={25} /> </div>
                </div>
            </div>
            
            <div className="course-lesson-container">
                <h3 className="section-title"> <Skeleton height={25} /></h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        <Skeleton variant="rectangle" height={30} width={30} />
                    </div>
                    <div className="skeleton-title"> <Skeleton height={25} /> </div>
                </div>
            </div>
    </div>
  );
}

export default LessonSkeleton;
