import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const KylieW = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    if(!user_id) {
       return <Redirect to='/workspace' />;
     }

  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2022/08/Image-from-iOS-1.jpg" alt="Kylie Lee" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Kylie Lee</h1>    
                            <p className="mentor-about">Hi! 
                            I've been in the industry for about 4 years and working at habit for 2. I specialize in blonding, lived in color and extensions. My favorite thing is being able to transform peoples lives through hair & helping stylists learn new skills so they can do the same with their own clients. I’m originally from Northern California but have been in Phoenix, AZ for almost 6 years now and got married to my husband last year.
                            Follow me on Instagram @hairby_kylielee so we can get to know each other.
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/4280070063?pwd=5QBDC44VMiOms5OpZPNzNpzwnud62v.1" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default KylieW;
