import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGOUT, SET_ERROR_MESSAGE } from './types';

export const login = (username, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            
        }
    }

    const body = JSON.stringify({username, password});

    try {
        dispatch({ type: LOGIN_REQUEST })
        const res = await axios.post('https://haircation.com/wp-json/jwt-auth/v1/token', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        console.log(err.response);
        dispatch({
            type: LOGIN_FAIL
        });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.code  
        })
    }
};

export const logout = () => dispatch => {
    dispatch({type: LOGOUT});
}


