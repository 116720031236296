import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store';
import PrivateRoute from '../utils/PrivateRoute';
import Navbar from '../layout/Navbar';
import Login from './auth/Login';
import Lander from './Lander';
import RedeemYearly from './banners/RedeemYearly';
import EducationLander from './landers/EducationLander';
import Workspace from './Workspace';
import SalonLocatorForm from './SalonLocatorForm';
import SinglePost from './SinglePost';
import Course from './SingleCourse';
import Profile from './Profile';
import Account from './Account';
import Footer from '../layout/Footer';
import Lesson from './hem/Lesson';
import Allison from './hem/mentors/Allison';
import Brittany from './hem/mentors/Brittany';
import Ali from './hem/mentors/Ali';
import Alicia from './hem/mentors/Alicia';
import Crista from './hem/mentors/Crista';
import Bryanna from './hem/mentors/Bryanna';
import Cassidy from './hem/mentors/Cassidy';
import KylieW from './hem/mentors/KylieW';
import Registration from './membership/Registration';
import Subscription from './account/Subscription';
import Formula from './Formula';
import Assignments from './hem/Assignments';
import PurchasePost from './membership/PurchasePost';
import Discount from './Discount';
import Locator from './Locator';
import OneOnOne from '../layout/OneOnOne';
import PaymentHistory from './account/PaymentHistory';
import Makinley from './hem/mentors/Makinley';
import PaigeY from './hem/mentors/PaigeY';
import Tatum from './hem/mentors/Tatum';
import Lauren from './hem/mentors/Lauren';
import ElliG from './hem/mentors/ElliG';
import InspoPreviews from '../marketing/InspoPreviews';
import CourseLesson from './CourseLesson';

const App = () => (
  
    <Provider store={store}>
        <Router>
            <Fragment>
                <RedeemYearly/>
                <Navbar/>
                <Route exact path='/' component={EducationLander} />
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/join/:id' component={Registration} />
                    <Route exact path='/purchase/:id' component={PurchasePost} />
                    <Route exact path='/workspace' component={Workspace} />
                    <Route exact path='/one-on-one' component={OneOnOne} />
                    <Route exact path='/salon-form' component={SalonLocatorForm}/>
                    <Route exact path='/locator' component={Locator}/>
                    <Route exact path='/lesson/:id' component={(props) => <SinglePost {...props} key={window.location.pathname}/>}/>
                    <Route exact path='/profile' component={Profile}/>
                    <Route exact path='/account/:id' component={Account}/>
                    <Route exact path='/subscriptions/:id' component={Subscription}/>
                    <Route exact path='/payments/:id' component={PaymentHistory}/>
                    <Route exact path='/discounts/' component={Discount}/>
                    <Route exact path='/course/lesson/:id' component={(props) => <Lesson  {...props} key={window.location.pathname}/>}/>
                    <Route exact path='/course/:id' component={(props) => <Course  {...props} key={window.location.pathname}/>}/>
                    <Route exact path='/course/assignment/:id' component={(props) => <Assignments  {...props} key={window.location.pathname}/>}/>
                    <Route exact path='/formula/:id' component={(props) => <Formula  {...props} key={window.location.pathname}/>}/>
                     
                    <Route exact path='/learn/inspo/:id' component={InspoPreviews}/>

                    <Route exact path='/mentor/allison' component={Allison}/>
                    <Route exact path='/mentor/brittany' component={Brittany}/>
                    <Route exact path='/mentor/brittany' component={Brittany}/>
                    <Route exact path='/mentor/ali' component={Ali}/>
                    <Route exact path='/mentor/alicia' component={Alicia}/>
                    <Route exact path='/mentor/crista' component={Crista}/>
                    <Route exact path='/mentor/bryanna' component={Bryanna}/>
                    <Route exact path='/mentor/makinley' component={Makinley}/>
                    <Route exact path='/mentor/paigey' component={PaigeY}/>
                    <Route exact path='/mentor/tatum' component={Tatum}/>
                    <Route exact path='/mentor/lauren' component={Lauren}/>
                    <Route exact path='/mentor/cassidy' component={Cassidy}/>
                    <Route exact path='/mentor/KylieW' component={KylieW}/>
                    <Route exact path='/mentor/ElliG' component={ElliG}/>
                </Switch>
            </Fragment>
        </Router>
        <Footer/>
    </Provider>
)

export default App;