import React from 'react';
import { useSelector } from 'react-redux';


const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const { currentPage } = useSelector(({ pagination }) => pagination);

    const pageNumbers = [];

    const numPages = Math.ceil(totalPosts / postsPerPage);

    for(let i = 1; i <= numPages; i++) {
      
      if (i <= 1 || //the first 3 pages 
         i === numPages || //the last page
        Math.abs(currentPage - i) <= 1 //the current page and the one before and after
       )
      pageNumbers.push(i);
      
    }

  
  return (
    <div className="pagination">
      {pageNumbers?.map(number => (
          <li className="pagination-keys" key={number}>
              <button className={ currentPage === number ? 'pagination-btn active' : 'pagination-btn'} onClick={() => paginate(number)}>{number}</button>
          </li>
      ))}
    </div>
  );
}

export default Pagination;
