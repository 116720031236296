import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer-container">
        <h1 className="footer-title">Need a hand?</h1>
        <p className="footer-text">We're available Mon-Fri from 9am - 5pm (Arizona Time)</p>
        <div className="contact-container">
            <div className="contact-meta">
                <img src="https://haircation.com/wp-content/uploads/2019/11/bell-3.png" alt="" className="contact-img" />
                <p className="contact-text">FAQ</p>
            </div>
            <div className="contact-meta">
                <img src="https://haircation.com/wp-content/uploads/2019/11/text.png" alt="" className="contact-img" />
                <p className="contact-text">Text</p>
            </div>
            <div className="contact-meta">
                <img src="https://haircation.com/wp-content/uploads/2019/11/chat.png" alt="" className="contact-img" />
                <p className="contact-text">Live Chat</p>
            </div>   
        </div>
        <hr className="footer-divider"/>

        <a className="policies">Privacy Policy</a>
        <a className="policies">Terms of Use</a>
    
    </div>
  );
}

export default Footer;
