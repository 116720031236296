import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMember } from '../../../actions/member';
import Lesson from '../HEMLesson';
import './mentor.css';

const Tatum = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
      }

  return (
    <Fragment>  
        <div className="mentor-container">      
                    <div className="column-wrapper">
                        <div className='row'>
                            <div className='column'>
                                <div className='image-column'>
                                    <div className="image-container">
                                        <img src="https://haircation.com/wp-content/uploads/2022/03/image0-scaled.jpeg" alt="Ali Gallego" className="mentor-image" />
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='mentor-column'>
                                    <h1 className="mentor-name">Tatum Dawson</h1>    
                                    <p className="mentor-about">Hi! I'm Tatum Dawson and I'm super excited to be your Habit Extension Method instructor!
                                    Education is one of my very favorite things and that makes me super passionate about teaching Habit Extension Method! 
                                    I have been at Habit salon for 4 years and have been actively doing extensions for all 4 of those years! 
                                    While I continue to work I am also working on finishing my bachelors in science. I'm excited to get to know all of you!
                                    </p>  
                                    <div className="mentor-live-container">
                                        <a href="https://us04web.zoom.us/j/4468669266?pwd=WkYxMno0ZEo5V1c0aFBodHNEY01SZz09" className="mentor-live">Go To Live Room</a>  
                                    </div>
                                    <div className="extension-workbook-container">
                                        <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                                    </div>
                                    <p className="scroll-hint">👇 Scroll for the course 👇</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Lesson/>
        </Fragment>


  );
}

export default Tatum;
