import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMember } from '../../../actions/member';
import Lesson from '../HEMLesson';
import './mentor.css';

const Makinley = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
      }

  return (
    <Fragment>  
        <div className="mentor-container">      
                    <div className="column-wrapper">
                        <div className='row'>
                            <div className='column'>
                                <div className='image-column'>
                                    <div className="image-container">
                                        <img src="https://haircation.com/wp-content/uploads/2022/03/image1-scaled.jpeg" alt="Ali Gallego" className="mentor-image" />
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='mentor-column'>
                                    <h1 className="mentor-name">Makinley Marks</h1>    
                                    <p className="mentor-about">Hi!! I'm Makinley Marks and I'm so excited to be your Habit Extension Method instructor!
                                    I have been working at Habit Salon for four years now, and extensions were the first thing I wanted to get my hands on and learn!
                                    I was born and raised in AZ and I don't think I'll ever leave.
                                    If I'm not at the salon I'm usually planning a trip to the beach, or hanging at home watching a crime documentary.
                                    Find me on insta @hair.by.makinley so we can get to know each other better!
                                    </p>  
                                    <div className="mentor-live-container">
                                        <a href="https://us05web.zoom.us/j/2475640277?pwd=M2lGRU9QQnZpdzNId0lpMXh5ZndVUT09" className="mentor-live">Go To Live Room</a>  
                                    </div>
                                    <div className="extension-workbook-container">
                                        <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                                    </div>
                                    <p className="scroll-hint">👇 Scroll for the course 👇</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Lesson/>
        </Fragment>


  );
}

export default Makinley;
