import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './educationLander.css';
const EducationLander = () => {
    const { token } = useSelector(({ auth }) => auth);

    if (token) {
        return <Redirect to='/workspace' />;
      }
  return (
    <Fragment>
        <div className="education-lander-container">
            <div className="lander-hero">
                <img className="haircation-preview" src="https://haircation.com/wp-content/uploads/2022/02/display-480x338.png" alt=""  />
                <h1 className="lander-value">Education For Hairstylists</h1>
            </div>

            <div className="lander-benefits">
                <ul className="benefits">
                    <li className="benefit-list">• 2x Training Tutorials / Month</li>
                    <li className="benefit-list">• Private Facebook Group</li>
                    <li className="benefit-list">• Featured On Our Salon Locatgor</li>
                    <li className="benefit-list">• Monthly Private Live Q+A</li>
                    <li className="benefit-list">• 20% Off Certification Classes</li>
                    <li className="benefit-list">• Discounts on Hair Products & Supplies</li>
                    <img className="haircation-price" src="https://haircation.com/wp-content/uploads/2022/03/monthly-membership.jpg" alt=""  />
                </ul>
            </div>

            <div className="lander-details">
                <h1 className="lander-value">This Membership Is For You</h1>
                <p className="lander-description">We know you’re busy with life, 
                    we know you may just be starting your career, 
                    and we know you want to improve your skills. 
                    This is exactly why we have designed everything to be convenient,
                    detailed, and accessible where ever you go.
                 </p>
                 <div className="haircation-cta">
                    <Link className="lander-btn" to='/join/214477'>Register Now</Link>
                 </div>
                <p className="membership-price">$75 / mo</p>

                <h1 className="lander-preview">Take a quick look at our <span className="underline">Inspo Series</span> </h1>
                <p className="preview-description">Here is our sneak peak of the <span className="bold">Inspo Series </span>
                 with @kendydu. Watch her transformation.
                </p>
                <div className="featured-video-container">
                    <iframe src="https://player.vimeo.com/video/684358882?h=fc33fdda1a" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>

            <div className="lander-action">
                <img className="featured-style" src="https://haircation.com/wp-content/uploads/2022/02/kendall-ci.png" alt=""  />
                <img className="featured-cta" src="https://haircation.com/wp-content/uploads/2022/02/lets-do-this.png" alt="" />
                <img className="featured-cta" src="https://haircation.com/wp-content/uploads/2022/02/phone-square.png" alt="" />
                <h1 className="lander-educators">The educators you will learn from</h1>
                <p className="preview-description">Haircation was created by industry leading hair stylists. 
                These hairstylists share their experiences they have with actual clients. 
                The techniques, theory, 
                and methods you will learn on Haircation will springboard you to the next level of your career.
                </p>

                <div className="educator-container">
                    <img  className="educator-image" src="https://haircation.com/wp-content/uploads/2022/02/20abfc2c-5831-435d-9086-934e77352085-habit_salon_chrissy_picture.jpg" alt=""/>
                    <p className="educator-caption">@hairby_chrissy & her team</p>
                </div>

                <div className="educator-container">
                    <img  className="sub-educator-image image-rounded" src="https://haircation.com/wp-content/uploads/2021/03/danielm-mentor.jpg" alt=""/>
                    <p className="educator-caption">@danielmbeauty</p>
                </div>

                <div className="educator-container">
                    <img  className="sub-educator-image image-rounded" src="https://haircation.com/wp-content/uploads/2021/03/chris-mentor.jpg" alt=""/>
                    <p className="educator-caption">@chrisweberhair</p>
                </div>

                <div className="educator-container">
                    <img  className="sub-educator-image image-rounded" src="https://haircation.com/wp-content/uploads/2021/03/kaitlyn-mentor.jpg" alt=""/>
                    <p className="educator-caption">@hairbykaitlynbrown</p>
                </div>
                    <p className="educator-caption m-4">& many many more!</p>

                <div className="haircation-cta">
                    <Link className="lander-btn" to='/join/214477'>Register Now</Link>
                 </div>
                <p className="membership-price">$75 / mo</p>

            </div>
        </div>
    </Fragment>
  );
}

export default EducationLander;
