import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './salonLocatorForm.css';

const SalonLocatorForm = () => {
    const { user_id } = useSelector(({ auth }) => auth);

    console.log(user_id);

    return (
        user_id ?
        <div  className="salon-locator-form">
            <h1 style={{ textAlign: 'center' }} >Enter your Salon Information Below</h1>
           <iframe style={{width: '100%', border: '0px'}} src="https://www.cognitoforms.com/f/k4B2Ep9dWUejG2n-JVhacQ/17"  height="1049"></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
        </div>
        :
        <Redirect to='/login' />
    )
};

export default SalonLocatorForm;