import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMember } from '../actions/member';
import './account.css';


const Account = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser } = useSelector(({ member }) => member);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);





  return (
    <div className="profile-form-container">
        <h1 className="profile-header">Profile Information</h1>
        <label htmlFor="first_name" className="profile-label bold">First Name</label>
        <p className="profile-label">{currentUser.first_name} {currentUser.last_name}</p>
        <label htmlFor="first_name" className="profile-label bold">Email Address</label>
        <p className="profile-label">{currentUser.email}</p>
        <label htmlFor="first_name" className="profile-label bold">Phone Number</label>
        <p className="profile-label">{currentUser.profile?.mepr_phone_number}</p>
    </div>
  );
}

export default Account;
