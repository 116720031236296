import axios from 'axios';
import { API_BASE_URL } from '../config';

const defaultOptions = {
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
};
const http = axios.create(defaultOptions);

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.auth = token ? `Bearer ${token}` : '';
    return config;
});

export default http;