import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLessonById, markLessonCompleted } from '../../actions/courseLessons';
import './assignment.css';


const Assignments = ({ match }) => {
    const { lesson, loading } = useSelector(({ lesson }) => lesson);
    const { mentor } = useSelector(({ member }) => member);
    const [ assignmentComplete, setAssignmentComplete ] = useState('Mark as completed');


    const dispatch = useDispatch();
    const id = match.params.id;
    console.log(id);

    useEffect(() => {
      window.scrollTo(0,0);
      dispatch(getLessonById(id))
    }, [lesson.assignments]);

    const onComplete = (id) => {
        dispatch(markLessonCompleted(id));
        setAssignmentComplete('Assignment is completed');
    }

    const getLinkForMentor = (mentor) => {
      if(mentor === 'Alicia Unfinished') {
          return `/mentor/alicia`
      } 

      if(mentor === 'Null') return ''

      if(mentor.includes('Completed')) {
         mentor =  mentor.split(' ')[0]
          return `/mentor/${mentor}`
      } 
          
      return `/mentor/${mentor}`
  }


  return (
      loading ? <h1>Loading...</h1> :
      <Fragment>
          <div dangerouslySetInnerHTML={{__html: lesson.assignments}}></div>
          <div className="assignment-btn-container">
            <button onClick={() => onComplete(id)} className="assignment-submit-btn">
                { lesson.completed_lesson ? 'Lesson is completed' : assignmentComplete}
            </button>
          </div>

        <div className="gb-container">
          <Link class="assignment-gb" to={getLinkForMentor(mentor)}>Go back to all lessons</Link>
        </div>
      </Fragment>
  );
}

export default Assignments;
