import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import './marketing.css';
import FAQ from './FAQs';


const InspoPreviews = ({ match }) => {
   
    const videos = [
        { id: 'https://vimeo.com/684358882', name: 'Transformation Tuesday with Kendall Hyzdu', description: `Kendall has been going to Chrissy since she was 16 years old. You may recognize Kendall from lots of Chrissy's posts and stories.` },
        { id: 'https://vimeo.com/714817851', name: `Laura Beverlin's Icy Blonde Transformation`, description: '' },
        { id: 'https://vimeo.com/715319353', name: 'Transformation Tuesday With Kelsey Lowrance', description: '' },
        { id: 'https://vimeo.com/715655866', name: 'Brand New Habit Salon', description: '' },
        { id: 'https://vimeo.com/715655806', name: 'Brighter Summer With Emily Gemma', description: '' },
        { id: 'https://vimeo.com/715664283', name: 'Madi Monroe Gets Habit Extensions', description: '' },
        { id: 'https://vimeo.com/715656094', name: 'Transforming Indy Blue To A Sandy Bronde', description: '' },
        { id: 'https://vimeo.com/715655680', name: 'Sierra Furtado Chops Off Her Habit Extensions', description: '' },
        { id: 'https://vimeo.com/715655573', name: 'Sky Brown Goes Bright Sunkissed', description: '' },
        { id: 'https://vimeo.com/715656002', name: 'Summer Makeover with Madi Prew', description: '' },
        { id: 'https://vimeo.com/715656044', name: 'Ins & Outs of Habit Extension Method', description: '' },
        { id: 'https://vimeo.com/715655474', name: 'Make Your Money Piece Pop', description: '' },
        { id: 'https://vimeo.com/715654523', name: 'Trendsetting Hair With Avani', description: '' },
        { id: 'https://vimeo.com/715655940', name: 'Blonde To Red Transformation', description: '' },
        { id: 'https://vimeo.com/715655940', name: 'Blonde To Red Transformation', description: '' },
      ];


    const id = match.params.id;
    const [ currentVideo, setCurrentVideo ] = useState(0);
    const [ showButton, setShowButton ] = useState(false);
    const video = videos[currentVideo];

    useEffect(() => {
        if(id) {
            setCurrentVideo(id);
        }
    }, []);

    const revealCTA = (p) => {
        if(p.percent > 0.5) {
            setShowButton(true);
        }
    }
    
    console.log(currentVideo);
    return (
        <div className="marketing-container">
            <h1 className="marketing-video-title">{video.name}</h1>
            <div className="marketing-vimeo-container">
                <Vimeo
                video={video.id === undefined ? currentVideo : video.id}
                autoplay
                responsive={true}
                onProgress={(p) =>revealCTA(p)}
                />
             </div>
             <p className={`marketing-cta-text cta-btn-${showButton}`}>If you like the content above. You can join Haircation today at a discounted price of $50 / mo. Normally the price is $75 / mo.</p>
             <div className="marketing-btn-container">
                <Link className={`marketing-cta-btn cta-btn-${showButton}`} to='/'>Join Haircation</Link>
             </div>
             <p className="marketing-description">{video.description}</p>
             <div className={`cta-btn-${showButton}`}>
                <FAQ/>
             </div>
        </div>
    )
}

export default InspoPreviews;