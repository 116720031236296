import React from 'react';
import { Skeleton } from '@material-ui/lab';


const UpnextVideosSkeleton = () => {
  return (
    <div className="upnext-videos-skeleton">
        <Skeleton variant="text" width={125} height={25} />
        <div className="video-container">
            <div className="upnext-thumbnail-container">
                <Skeleton variant="rect" height={120} />
            </div>
            <div className="upnext-meta">
                <Skeleton variant="text" width={125} height={25} />
            <div className="instructor-container">
                <Skeleton variant="text" width={125} height={25} />
            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            </div>
        </div>       
        <div className="video-container">
            <div className="upnext-thumbnail-container">
                <Skeleton variant="rect" height={120} />
            </div>
            <div className="upnext-meta">
                <Skeleton variant="text" width={125} height={25} />
            <div className="instructor-container">
                <Skeleton variant="text" width={125} height={25} />
            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            </div>
        </div>       
        <div className="video-container">
            <div className="upnext-thumbnail-container">
                <Skeleton variant="rect" height={120} />
            </div>
            <div className="upnext-meta">
                <Skeleton variant="text" width={125} height={25} />
            <div className="instructor-container">
                <Skeleton variant="text" width={125} height={25} />
            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            </div>
        </div>       
        <div className="video-container">
            <div className="upnext-thumbnail-container">
                <Skeleton variant="rect" height={120} />
            </div>
            <div className="upnext-meta">
                <Skeleton variant="text" width={125} height={25} />
            <div className="instructor-container">
                <Skeleton variant="text" width={125} height={25} />
            <img className="verification-badge" src="https://haircation.com/wp-content/uploads/2021/08/verification-badge.svg" alt="" />
            </div>
            </div>
        </div>       
  </div>
  );
}

export default UpnextVideosSkeleton;
