import React from 'react';
import { useSelector } from 'react-redux';
import './errorMessage.css';

const ErrorMessage = () => {
    const { error } = useSelector(({ error}) => error);


  return (
    <p className="error-message">{error.split('[jwt_auth]')}</p>
  );
}

export default ErrorMessage;
