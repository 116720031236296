import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMember } from '../../../actions/member';
import Lesson from '../HEMLesson';
import './mentor.css';

const PaigeY = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
      }

  return (
    <Fragment>  
        <div className="mentor-container">      
                    <div className="column-wrapper">
                        <div className='row'>
                            <div className='column'>
                                <div className='image-column'>
                                    <div className="image-container">
                                        <img src="https://haircation.com/wp-content/uploads/2020/01/Image-from-iOS-scaled.jpg" alt="Ali Gallego" className="mentor-image" />
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='mentor-column'>
                                    <h1 className="mentor-name">Paige Yetter</h1>    
                                    <p className="mentor-about">Hi! My name is Paige Yetter and I recently got married 7 months ago. 
                                    I've been doing hair for the past 4 years and I have been at Habit for 3 of those years. 
                                    Blondes, brondes and extensions are my favorite things to do, but I love all things hair. I'm so excited to work with you!
                                    </p>  
                                    <div className="mentor-live-container">
                                        <a href="https://us04web.zoom.us/j/4106586715?pwd=RG94R0NVYk9yYmFDMTJCMXZUbE9sdz09" className="mentor-live">Go To Live Room</a>  
                                    </div>
                                    <div className="extension-workbook-container">
                                        <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                                    </div>
                                    <p className="scroll-hint">👇 Scroll for the course 👇</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Lesson/>
        </Fragment>


  );
}

export default PaigeY;
