import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMember } from '../../../actions/member';
import Lesson from '../HEMLesson';
import './mentor.css';

const Alicia = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
      }

  return (
    <Fragment>  
        <div className="mentor-container">      
                    <div className="column-wrapper">
                        <div className='row'>
                            <div className='column'>
                                <div className='image-column'>
                                    <div className="image-container">
                                        <img src="https://haircation.com/wp-content/uploads/2020/01/Lauren-S.jpg" alt="Lauren Stiffler" className="mentor-image" />
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='mentor-column'>
                                    <h1 className="mentor-name">Lauren Stiffler</h1>    
                                    <p className="mentor-about">Hi! My name is Lauren Stiffler and I love my job at Habit salon. I've been here for about 3 years and it just gets better and better. 
                                    My favorite thing to do is boost people's confidence and make them feel beautiful. 
                                    I never imagined that I'd be at a salon as amazing as Habit and I'm so thankful to be apart of the team.
                                    </p>  
                                    <div className="mentor-live-container">
                                        <a href="https://us04web.zoom.us/j/8453133778?pwd=N1VRMEhVVnNXcTFsOW9LSU5Fakw2Zz09" className="mentor-live">Go To Live Room</a>  
                                    </div>
                                    <div className="extension-workbook-container">
                                        <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                                    </div>
                                    <p className="scroll-hint">👇 Scroll for the course 👇</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Lesson/>
        </Fragment>


  );
}

export default Alicia;
