import React from 'react';
import { Link } from 'react-router-dom';
import Play from '../../icons/Play';
import Completed from '../../icons/Completed';

const UpgradeLessons = ({ lesson }) => {
    return (
        <div key={lesson.id} className="lesson-link">
            <div className="course-lesson-container">
                <h3 className="section-title"> {lesson.section_heading} </h3>
                <div className="upgrade-lesson-section">
                    <div className="upgrade-lesson-metadata">
                        {<Play link={`/lesson/${lesson.id}`}/>  }
                        
                        <div className="upgrade-mobile-container">
                            <p className="upgrade-lesson-title">{lesson.title}</p>
                            <div className="upgrade-container">
                                <a href={lesson.upgrade_section} className="lesson-upgrade">Upgrade To Unlock</a>
                                <p className="upgrade-title">Use your Haircation email when upgrading</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeLessons;