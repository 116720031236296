import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMember } from '../../actions/member';
import Cancel from '../icons/Cancel';
import './membership.css';
import moment from 'moment';

const Subscription = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);
    

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

 
    // const latestTransaction = new Date(currentUser.latest_txn.created_at )
    // const renewDate = latestTransaction.setDate(latestTransaction.getDate() + 365);

  
  return (
      loading ? <p>Loading</p> :
    <div className="subscription-container">   
        <h1 className="subscription-header">Your active subscription(s)</h1>
        { currentUser?.active_memberships.map(membership => (
          <div key={membership.id} className="active-subscriptions">
          {/* <p className="subscription-renew">Your {membership.title} subscription renews on: <b>{moment(renewDate).format('MMMM DD Y')}</b></p> */}
            <div className="membership-container">
              <p className="subscription-title">{membership.title} / </p>
              <Cancel subscription={currentUser.latest_txn.subscription}/> 
            </div>
        </div>
        ))
        }
        

    </div>
  );
}

export default Subscription;
