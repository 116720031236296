import axios from 'axios';
import http from '../utils/http';
import { GET_MEMBER, GET_MEMBER_FAILED, GET_MEMBER_PROFILE_FAILED, GET_MEMBER_PROFILE_REQUEST, GET_MEMBER_PROFILE_SUCCESS, GET_MEMBER_REQUEST } from './types';

export const getMember = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'MEMBERPRESS-API-KEY': 'JmLD3IBMCd'
        }
    }

   
    try {
        dispatch({ type: GET_MEMBER_REQUEST })
        const res = await axios.get(`https://haircation.com/wp-json/mp/v1/members/${id}`, config);
        dispatch({
            type: GET_MEMBER,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_MEMBER_FAILED
        });
    }
}

export const getMemberProfile = () => async dispatch => {
    
    try {
        dispatch({type: GET_MEMBER_PROFILE_REQUEST});
        const res = await http.get('wp/v2/custom/profile');
        dispatch({
            type: GET_MEMBER_PROFILE_SUCCESS,
            payload: res.data
        })

    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_MEMBER_PROFILE_FAILED
        })        
    }
}