import React from 'react';

const Play = ({ link }) => {
  return (
    <a href={ link } className="play-icon">
         <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="8" fill="#FF8B85"/>
            <path d="M15.6432 11.152C16.2699 11.5437 16.2699 12.4563 15.6432 12.848L10.53 16.0438C9.86395 16.46 9 15.9812 9 15.1958L9 8.80425C9 8.01881 9.86395 7.53997 10.53 7.95625L15.6432 11.152Z" fill="white"/>
         </svg>
    </a>
  );
}

export default Play;
