import React, { useEffect, Fragment } from 'react';
import {  useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLessons } from '../../actions/courseLessons';
import './lesson.css'
import Play from '../icons/Play';
import Completed from '../icons/Completed';
import UpgradeLessons from './lessonStatuses/UpgradeLessons';
import UnavailableLessons from './lessonStatuses/UnavailableLessons';
import AvailableLessons from './lessonStatuses/AvailableLessons';


const CourseNextVideos = ({ lesson }) => {
    const { lessons } = useSelector(({ courseLessons }) => courseLessons);
    const { courseId } = useSelector(({ setCourseId }) => setCourseId);


    const dispatch = useDispatch();   
    console.log(lesson);
    

    useEffect(() => {
        dispatch(getLessons(courseId));
    }, [lessons.id]);

    let now = new Date().toLocaleDateString('en-us',  {year:"numeric", month:"long", day:"numeric"});


    const renderLessonStatus = (s) => {
        if(s.upgrade_section) return <UpgradeLessons lesson={s}/>
        if( new Date(now) < new Date(s.drip_date_as_date)) return <UnavailableLessons lesson={s}/>
        return <AvailableLessons lesson={s}/>
    }

    const hemLessons = lessons.map(lesson => (
        new Date(now) > new Date(lesson.drip_date_as_date) || courseId != '252414' ?
        <Link key={lesson.id} class="lesson-link" to={ lesson.assignments ? `/course/assignment/${lesson.id}` : `/course/lesson/${lesson.id}`}>
            <div className="next-lesson-container">
                <h3 className="section-title"> {lesson.section_heading} </h3>
                <div className="lesson-section">
                    <div className="lesson-metadata">
                        {!lesson.completed_lesson ? <Play link={`lesson/${lesson.id}`}/> : <Completed link={`lesson/${lesson.id}`}/>  }
                        <p className="lesson-title">{lesson.title}</p>
                    </div>
                </div>
            </div>
        </Link>
        :
        renderLessonStatus(lesson)
    ))

  return (
    <Fragment>
        {hemLessons}
    </Fragment>
  );
}

export default CourseNextVideos;
