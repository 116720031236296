import { LOGIN_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGOUT } from "../actions/types";

const initalState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: false,
    user: null,
    user_id: localStorage.getItem('user_id'),
};

 export default function  (state = initalState, action) {
    const { type, payload } = action;

    switch(type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token)
            localStorage.setItem('user_id', payload.user_id)
            localStorage.setItem('user', payload.user)
            localStorage.setItem('user_group', JSON.stringify(payload.group))
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token')
            localStorage.removeItem('user_id')
            localStorage.removeItem('user')
            localStorage.removeItem('user_group')
            localStorage.removeItem('active_subscriptions')
            localStorage.removeItem('mentor')
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                user_id: null
            }
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        default:
            return state;

    }
}
