import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';


const Allison = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    if(!user_id) {
       return <Redirect to='/workspace' />;
     }
   
  return (
      <Fragment>   
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2020/01/IMG-0003.jpg" alt="Allison Hallows" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Allison Hallows</h1>    
                            <p className="mentor-about">Hi there! My name is Allison Hallows & I’m super excited to
                            be your Instuctor in learning the Habit Extension Method!
                                A little about me…. I’ve been at Habit Salon since June 2014
                                I’m a born & raised AZ girl.
                                When I’m not at Habit Salon, you can find me in the gym, traveling or hanging out with my two boys. Follow me on Instagram @hairbyallih for more of that!
                                I look forward to getting to know you!
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://zoom.us/j/4544136839?pwd=bU5UVnA4MkxiWHRpTVpQUWJmM3Vadz09" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default Allison;
