import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMember } from '../../../actions/member';
import Lesson from '../HEMLesson';
import './mentor.css';

const Alicia = () => {
    const { user_id } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);

 

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

    if(!user_id) {
        return <Redirect to='/workspace' />;
    }

  return (
    <Fragment>  
        <div className="mentor-container">      
                    <div className="column-wrapper">
                        <div className='row'>
                            <div className='column'>
                                <div className='image-column'>
                                    <div className="image-container">
                                        <img src="https://haircation.com/wp-content/uploads/2020/01/5E5A3720.jpg" alt="Ali Gallego" className="mentor-image" />
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='mentor-column'>
                                    <h1 className="mentor-name">Alicia Webster</h1>    
                                    <p className="mentor-about">I’ve been in the industry for 6 years and working at Habit for almost 4 years.
                                        I love to teach and I am passionate about helping others become more successful.
                                        My specialty is extensions and hair color, specifically babylights and balayage.
                                        If you attend my class, you can expect a lot of encouragement, support and help to achieve the self confidence and skill you are looking for. Oh and a lot of laughter!
                                        I love having fun!
                                        Fun fact.. I am a mom of 7 kids and I absolutely love it ❤
                                    </p>  
                                    <div className="mentor-live-container">
                                        <a href="https://us04web.zoom.us/j/6092633030?pwd=VTJveXZkY3VJaXZiUHZNUUF6dG1idz09" className="mentor-live">Go To Live Room</a>  
                                    </div>
                                    <div className="extension-workbook-container">
                                        <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                                    </div>
                                    <p className="scroll-hint">👇 Scroll for the course 👇</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Lesson/>
        </Fragment>


  );
}

export default Alicia;
