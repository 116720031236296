import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getMember } from '../actions/member';
import Announcement from '../layout/Announcement';
import ProfileImage from '../layout/ProfileImage';
import Login from './auth/Login';
import Posts  from './Posts';
import Preview from './Preview';
import './workspace.css';
import moment from 'moment'
import RedeemYearly from './banners/RedeemYearly';

const Workspace = () => {
    const { user_id, token } = useSelector(({ auth }) => auth);
    const { currentUser, loading } = useSelector(({ member }) => member);
   
   

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMember(user_id));
    }, [user_id]);

   
    // const JWT = token;
    // const jwtPayload = JSON.parse(window.atob(JWT.split('.')[1]))
    
    // if(new Date(jwtPayload.exp * 1000) < new Date()) {
    //   return <Redirect to='/login' />;
    // }

    return (
        <div className="workspace-container">
            { !user_id ? 
            <Fragment>
                <Login location={'workspace-login'}/>
                <Announcement/>
                <Preview/> 
            </Fragment>
            :
            <Fragment>
               <div className="profile-meta-container">
                   <ProfileImage/>
                    { !loading ? <h1 className="profile-display">Hi {currentUser.first_name}! </h1> : <h1>Loading...</h1>} 
                    { !loading ? <p className="member-since-display">Member Since {moment(currentUser.registered_at).format('MMMM Y') } </p> : <p>Loading...</p>} 
                    <div className="profile-link-container">
                        <Link className="workspace-btn" to='/profile'>Profile</Link>
                        <Link className="workspace-btn" to='/discounts'>Discounts</Link>
                        <Link className="workspace-btn" to='/salon-form'>Salon Locator Form</Link>
                    </div>
                </div>
                <Announcement/>
                {/* <RedeemYearly currentUser={currentUser}/> */}
                <Posts/>
            </Fragment>
            }
         
        </div>
    )
};

export default Workspace;