import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { getCategories } from '../actions/category';
import { logout } from '../actions/auth';
import './categorySelector.css';


const CategorySelector = ({ categoryOpen, setCategoryOpen, setSelectCategory }) => {
  const { categories, loading } = useSelector(({ category }) => category);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getCategories())
  }, [categories.cat_id]);

  const categorySelect = (id, state, type) => {
    setSelectCategory(`?${type}=${id}`);
    setCategoryOpen(state)
  }
  
  return (

   categories.jwt_auth_invalid_token ? dispatch(logout()) : 
    <Fragment>
      <svg 
      className={categoryOpen ? "close-image" : "hidden-image"} 
      onClick={() => setCategoryOpen(false)}
      width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z" fill="white"/>
      </svg>
     { loading && categories.length > 1 ? null :
        <ul className={categoryOpen ? "category-navigation-list open" : "category-navigation-list closed"}>
            <li onClick={() => categorySelect('', false, '')} className="category-navigation-items">All Categories </li>
            <li onClick={() => categorySelect('enrolled', false, 'content')} className="category-navigation-items">Courses</li>
           { 
            categories?.map(cat => (
              <li key={cat.cat_id} onClick={() => categorySelect(cat.cat_id, false, 'category_id')} className="category-navigation-items">{cat.cat_title}</li>
              ))  
            
            }
            
        </ul>}
    </Fragment>
  );
}

export default CategorySelector;
