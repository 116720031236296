import { SET_ERROR_MESSAGE } from "../actions/types";

const initalState = {
    error: ''
}

export default function ( state = initalState, action ) {
    const { type, payload } = action;
    
    switch(type) {
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                error: payload
            }
        default: 
            return state;
    }
}