import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getMembership } from '../../actions/membership';
import { createMembership } from '../../actions/registration';
import './registration.css';
import Cleave from 'cleave.js/react'

const Registration = ({ match }) => {
    const { membership } = useSelector(({ membership }) => membership);
    const { registration, loading } = useSelector(({ registration }) => registration);
    const dispatch = useDispatch();
    const id = match.params.id;

    
    const [ formData, setFormData ] = useState({
        email: '', 
        password: '',
        username: '', 
        card_num: '', 
        cvv_code: '',
        cc_exp: '',
        cc_exp_year: '',
        cc_exp_month: '',
        first_name: '', 
        last_name: ''
    })
    const { email, password,  card_number, cvv_code, cc_exp, first_name, last_name } = formData;

    const onChange = e =>  {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }    

      
    const onSubmit = e => {
        e.preventDefault();
        const [cc_exp_month, cc_exp_year] = formData.cc_exp.split('/');
        const username = email;
        const card_num = parseInt(card_number.replace(/ /g, ''))
        console.log(`This is card number ${card_num}`);
        const data = {
            ...formData,
            cc_exp_month,
            cc_exp_year,
            card_num,
            username
        };
        console.log(`This is the data ${data}`);
        dispatch(createMembership(data, id));
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
      dispatch(getMembership(id))
    }, [membership.id]);

    if (registration.result === 'Success') {
        return <Redirect to="/login"/>;  
      }

  return (
    <div className="registration-container">
        <div className="registration-content">
          <h1 className="membership-title">
              {membership.title}
          </h1>
        <img src="https://haircation.com/wp-content/uploads/2021/11/HEM-C.jpg" alt="2 girls" className="membership-image" />
        <div dangerouslySetInnerHTML={{__html: membership.content}}></div>
        </div>
        <div className="registration-form">
            <form className="member-form" onSubmit={e => onSubmit(e)}>
                <label htmlFor="" className="member-label">First Name</label>
                <input 
                type="text" 
                className="member-input"
                name="first_name"
                value={first_name}
                onChange={e => onChange(e)} 
                />

                <label htmlFor="" className="member-label">Last Name</label>
                <input 
                type="text" 
                className="member-input"
                name="last_name"
                value={last_name}
                onChange={e => onChange(e)}
                />

                <label htmlFor="" className="member-label">Email Address</label>
                <input 
                 type="email"
                 className="member-input" 
                 name="email"
                 value={email}
                 onChange={e => onChange(e)}
                />

                {/* <label htmlFor="" className="member-label">Username</label>
                <input 
                 type="text"
                 className="member-input" 
                 name="username"
                 value={username}
                 onChange={e => onChange(e)}
                 /> */}

                {/* <label htmlFor="" className="member-label">Phone</label>
                <input type="tel" className="member-input" /> */}

                <label htmlFor="" className="member-label">Password</label>
                <input
                 type="password" 
                 className="member-input"
                 name="password"
                 value={password}
                 onChange={e => onChange(e)}
                 />

                <div className="credit-card-form">
                    <div className="credit-card-images">
                        <img src="https://haircation.com/wp-content/uploads/2021/11/visa.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/master.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/discover.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/american_express.svg" alt="" className="cc-image" />
                    </div>
                    <Cleave
                     placeholder="Enter card number" 
                     className="member-input" 
                     options={{ creditCard: true }} 
                     name="card_number"
                     value={card_number}
                     onChange={e => onChange(e)}
                     />
                <div className="credit-card-meta">
                    <div className="credit-container">
                        <label htmlFor="" className="member-label">Expiration Date</label>
                        <Cleave 
                        placeholder="MM/YY" 
                        className="member-input" 
                        options={{date: true, datePattern: ['m', 'y']}}
                        name="cc_exp"
                        value={cc_exp}
                        onChange={e => onChange(e)}
                         />

                    </div>
                    <div className="credit-container">
                        <label htmlFor="" className="member-label">CCV</label>
                        <input 
                        placeholder="CVV"
                         maxLength="4" 
                         type="password" 
                         className="member-input" 
                         name="cvv_code"
                         value={cvv_code}
                         onChange={e => onChange(e)}
                         />
                    </div>
                </div>
                </div>

                <div className="order-overview">
                    <h1 className="order-title">Order Summary</h1>
                    <div className="overview-price">
                        <p className="order-total">Total:</p>
                        { membership.trial === "1" ? 
                            <div className="discount-price-container">
                                <p className="order-trial"> ${membership.trial_amount} for {membership.trial_days} days </p>
                                <p className="order-price">  then {membership.price} / {membership.period_type} </p>
                            </div>
                             :
                            <p className="order-price"> ${membership.price} / {membership.period_type}</p>
                        }
                    </div>
                </div>

                <input type="submit" value={loading ? "Loading..." :  membership.signup_button_text} className="member-input member-submit" />
            </form>
        </div>
    </div>
  );
}

export default Registration;
