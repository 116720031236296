import React from 'react';
import { cancelMembership } from '../../actions/membership'
import { useDispatch } from 'react-redux';


const Cancel = ({ subscription }) => {

  const dispatch = useDispatch();

  const cancelSubscription = (id) => {
    dispatch(cancelMembership(id));
}

  return (
    <button
     className="cancel-subscription-btn"
     onClick={ () => cancelSubscription(subscription)}>
      Cancel Membership
    </button>
      // <svg onClick={ () => cancelSubscription(subscription)}  className="cancel-subscription" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      //     <path d="M6.5 18.9139L5.086 17.4999L17.9999 4.58598L19.4139 5.99998L6.5 18.9139Z" fill="#F25767"/>
      //     <path d="M12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22ZM12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4Z" fill="#F25767"/>
      // </svg>
    
  );
}

export default Cancel;
