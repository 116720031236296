import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglePost } from '../../actions/posts';
import Cleave from 'cleave.js/react';
import './purchasePost.css';
import { completeSinglePurchase } from '../../actions/registration';
import { Redirect } from 'react-router-dom';
import { login } from '../../actions/auth';


const PurchasePost = ({ match }) => {
    const { token } = useSelector(({ auth }) => auth);
    const { lesson } = useSelector(({ singlePost }) => singlePost);
    const { purchasePost, loading } = useSelector(({ registration }) => registration);
    const id = match.params.id;
    const dispatch = useDispatch();

    const [ formData, setFormData ] = useState({
        card_num: '',
        cvv_code: '',
        cc_exp: '',
        cc_exp_month: '',
        cc_exp_year: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        mepr_phone_number: '',
        username: ''
    })

    const { card_num, cvv_code, cc_exp, cc_exp_month, cc_exp_year, first_name, last_name, mepr_phone_number, username, email, password } = formData;
    
    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();
        const [cc_exp_month, cc_exp_year] = formData.cc_exp.split('/');
        const username = email;
        const data = {
            ...formData,
            cc_exp_month,
            cc_exp_year,
            username
        };


        dispatch(completeSinglePurchase(data, id));
        dispatch(login(data.username, formData.password ))
    }
  
    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(getSinglePost(id))
    }, [lesson.title]);

    // Redirect if logged in
    if (purchasePost) {
        return <Redirect to={`/lesson/${id}`} />
    }

  return (
    <div className="purchase-post-parent-container">
        <div className="registration-content">
          <h1 className="membership-title">
              {lesson.title}
          </h1>
        <img src={lesson.app_thumbnail} alt="Image of this post" className="post-image" />
          <p className="post-lesson-description">{lesson.lesson_description}</p>
        <p className="membership-description"> {lesson.description} </p>
        </div>
        <div className="purchase-post-container">
            <form onSubmit={e => onSubmit(e)} className="purchase-post">
            {!token && 
            <Fragment>
                <label htmlFor="" className="purchase-post-label">First Name</label>
                <Cleave
                     placeholder="First Name" 
                     className="member-input" 
                     name="first_name"
                     value={first_name}
                     onChange={e => onChange(e)}
                     />
                <label htmlFor="" className="purchase-post-label">Last Name</label>
                <Cleave
                     placeholder="Last Name" 
                     className="member-input" 
                     name="last_name"
                     value={last_name}
                     onChange={e => onChange(e)}
                     />
                <label htmlFor="" className="purchase-post-label">Email Address/Username</label>
                <Cleave
                     placeholder="Email Address" 
                     className="member-input" 
                     name="email"
                     value={email}
                     onChange={e => onChange(e)}
                     />
                <label htmlFor="" className="purchase-post-label">Password</label>
                <Cleave
                     placeholder="Create your password" 
                     className="member-input" 
                     name="password"
                     value={password}
                     onChange={e => onChange(e)}
                     type="password"
                     />
                <label htmlFor="" className="purchase-post-label">Phone Number</label>
                <Cleave
                     options={{ numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"] }}
                     placeholder="Phone Number" 
                     className="member-input" 
                     name="mepr_phone_number"
                     value={mepr_phone_number}
                     onChange={e => onChange(e)}
                     />

            </Fragment>
                
            } 
                <label htmlFor="" className="purchase-post-label">Credit or Debit Card</label>
                <div className="credit-card-images">
                        <img src="https://haircation.com/wp-content/uploads/2021/11/visa.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/master.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/discover.svg" alt="" className="cc-image" />
                        <img src="https://haircation.com/wp-content/uploads/2021/11/american_express.svg" alt="" className="cc-image" />
                </div>
                <Cleave
                     placeholder="Enter card number" 
                     className="member-input" 
                     options={{ creditCard: true }} 
                     name="card_num"
                     value={card_num}
                     onChange={e => onChange(e)}
                     />
                <div className="credit-card-meta">
                    <div className="credit-container">
                        <label htmlFor="" className="member-label">Expiration Date</label>
                        <Cleave 
                        placeholder="MM/YY" 
                        className="member-input" 
                        options={{date: true, datePattern: ['m', 'y']}}
                        name="cc_exp"
                        value={cc_exp}
                        onChange={e => onChange(e)}
                         />

                    </div>
                    <div className="credit-container">
                        <label htmlFor="" className="member-label">CCV</label>
                        <input 
                        placeholder="CVV"
                         maxLength="4" 
                         type="password" 
                         className="member-input" 
                         name="cvv_code"
                         value={cvv_code}
                        onChange={e => onChange(e)}
                         />
                    </div>
                </div>
                <div className="order-overview">
                    <h1 className="order-title">Order Summary</h1>
                    <div className="overview-price">
                        <p className="order-total">Total:</p>
                            <div className="order-total-container">
                                <p className="order-trial"> ${lesson.price} (One Time) </p>   
                            </div>      
                    </div>
                </div>
                <input type="submit" value={loading ? 'Loading...' : 'Purchase Lesson'} className="purchase-post-btn" />
            </form>

        </div>
    </div>
  );
}

export default PurchasePost;
