import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMemberProfile } from '../actions/member';
import Uploady, { useBatchAddListener } from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import UploadPreview from '@rpldy/upload-preview';
import './profileimage.css';

const ProfileImage = () => {
    const [ preview, setPreview ] = useState(false);
    const { token } = useSelector(({ auth }) => auth);
    const { profile, loading } = useSelector(({ member }) => member);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getMemberProfile())
    }, [profile.profile_image]);

    const setPreviewImage = (status) => {
        setPreview(status);
    }
   
  return (
    <div className="profile-image-container">
        <Uploady
        destination={{url: "https://haircation.com/wp-json/wp/v2/custom/profile-image/"}}>
      
      <UploadButton
            inputFieldName="image"
            sendWithFormData={true}
            destination={{
                headers: {
                    'auth': `Bearer ${token}`
                }
            }}
        >
      <svg onClick={() => setPreviewImage(true)} width="25" height="25" viewBox="0 0 358 358" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="179" cy="179" r="179" fill="#338DFF"/>
        <line x1="178" y1="95" x2="178" y2="263" stroke="white" strokeWidth="18"/>
        <line x1="259" y1="180" x2="91" y2="180" stroke="white" strokeWidth="18"/>
     </svg>

        </UploadButton>

        <div className="profile-image-thumbnail">
            { preview ? <UploadPreview/> : <img src={profile.profile_image} alt="" /> }
        </div>

        </Uploady>
    </div>
  );
}

export default ProfileImage;
