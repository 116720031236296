import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lesson from '../HEMLesson';
import './mentor.css';

const Cassidy = () => {

    const { user_id } = useSelector(({ auth }) => auth);
    
    if(!user_id) {
       return <Redirect to='/workspace' />;
     }

  return (
      <Fragment>
        <div className="mentor-container">
            <div className="column-wrapper">
                <div className='row'>
                    <div className='column'>
                        <div className='image-column'>
                            <div className="image-container">
                                <img src="https://haircation.com/wp-content/uploads/2020/01/Cassidy-Campbell.jpg" alt="Cassidy Campbell" className="mentor-image" />
                            </div>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='mentor-column'>
                            <h1 className="mentor-name">Cassidy Campbell</h1>    
                            <p className="mentor-about">Hi! 
                            I recently got married a little over two months ago. 
                            I have been working at Habit Salon for four and a half years now and have loved every minute! 
                            I started out at the front desk as a receptionist while I was finishing hair school, then jumped right into assisting and now taking my own clients. 
                            I love blondes, brondes, extensions and braids! I love teaching and can’t wait to share my education with you!
                            </p>  
                            <div className="mentor-live-container">
                                <a href="https://us04web.zoom.us/j/2472371151?pwd=MXYzN3p3MzJ6eHppSGxEQUUxOUtlZz09" className="mentor-live">Go To Live Room</a>  
                            </div>
                            <div className="extension-workbook-container">
                                <a href="https://haircation.com/wp-content/uploads/2020/10/New-Habit-Hand-Tied-Extension-Packet.pdf" className="workbook">View Extension Workbook</a>
                            </div>
                            <p className="scroll-hint">👇 Scroll for the course 👇</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Lesson/>
      </Fragment>

  );
}

export default Cassidy;
