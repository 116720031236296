import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import './lesson.css'
import { getLessons, setCourseId } from '../../actions/courseLessons';
import Play from '../icons/Play';
import Completed from '../icons/Completed';
import LessonSkeleton from './mentors/LessonSkeleton';
import UpgradeLessons from './lessonStatuses/UpgradeLessons';
import UnavailableLessons from './lessonStatuses/UnavailableLessons';
import AvailableLessons from './lessonStatuses/AvailableLessons';

const Lesson = () => {
  const { token } = useSelector(({ auth }) => auth);

    const { lessons, loading } = useSelector(({ courseLessons }) => courseLessons);

    const dispatch = useDispatch();
    

    useEffect(() => {
        dispatch(getLessons('252414'));
        dispatch(setCourseId('252414'));
    }, [lessons.id]);

    const JWT = token;
    const jwtPayload = JSON.parse(window.atob(JWT.split('.')[1]))
    
    if(new Date(jwtPayload.exp * 1000) < new Date()) {
      return <Redirect to='/login' />;
    }

    let now = new Date().toLocaleDateString('en-us',  {year:"numeric", month:"long", day:"numeric"});

    const renderLessonStatus = (s) => {
        if(s.upgrade_section) return <UpgradeLessons lesson={s}/>
        if( new Date(now) < new Date(s.drip_date_as_date)) return <UnavailableLessons lesson={s}/>
        return <AvailableLessons lesson={s}/>
    }



    const hemLessons = lessons.map(lesson => (
       renderLessonStatus(lesson)
    ))


  return (
    <div className="mentor-lesson-container">
        <h1 className="course-header">Start your course</h1>
        { loading ? <LessonSkeleton />
        : 
          hemLessons        
        }
    </div>
  );
}

export default Lesson;
